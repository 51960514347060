import useSWR, { KeyedMutator } from 'swr';
import { Dashboard } from '@tensorleap/api-client';
import api from '../api-client';
import { useMergedObject } from '../useMergedObject';
import { REFRESH_INTERVALS } from './consts';
import { VisualizationFilter } from '../types/filters';
import { useMemo } from 'react';

export interface UseDashboards {
  dashboards: Dashboard[];
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<Dashboard[]>;
}

export interface UseDashboardsProps {
  projectId: string;
}

export function useFetchDashboards({
  projectId,
}: UseDashboardsProps): UseDashboards {
  const { data: dashboards, error, mutate } = useSWR<Dashboard[], Error>(
    projectId,
    async () => {
      const { dashboards } = await api.getProjectDashboards({ projectId });
      return dashboards;
    },
    {
      refreshInterval: REFRESH_INTERVALS.dashboards,
    }
  );

  return useMergedObject({
    dashboards: dashboards || [],
    error,
    isLoading: !error && !dashboards,
    refetch: mutate,
  });
}

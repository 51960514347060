import { Session, SessionRunData } from '@tensorleap/api-client';
import { Divider, IconButton, Popover } from '@material-ui/core';
import { RefObject, useCallback, useMemo, useRef, useState } from 'react';
import { AddToDashboardToggle } from './AddToDashboardToggle';
import React from 'react';
import { ConfirmDialog } from '../ui/atoms/DeleteContentDialog';
import { Trash } from '../ui/icons';
import { useVersionControl } from '../core/VersionControlContext';
import { useOutsideClick } from '../core/useOutsideClick';
import { bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import { orderBy } from 'lodash';
import { EditableInputCell } from '../ui/atoms/EditableInputCell';
import api from '../core/api-client';

export interface SessionRunsToggleParams {
  session: Session;
  isOpen: boolean;
  handleClose: () => void;
  iconButtonRef: RefObject<HTMLDivElement>;
}

export function SessionRunsToggle({
  session,
  isOpen,
  handleClose,
  iconButtonRef,
}: SessionRunsToggleParams): JSX.Element | null {
  const { refetch, deleteSessionRun } = useVersionControl();
  const [sessionRunToDelete, setSessionRunToDelete] = useState<
    SessionRunData | undefined
  >(undefined);

  const showDivider = useCallback(
    (index: number) => index !== (session.sessionRuns?.length ?? 0) - 1,
    [session.sessionRuns?.length]
  );

  const evaluationPanelRef = useRef<HTMLInputElement>(null);
  const deletePanelRef = useRef<HTMLDivElement>(null);

  useOutsideClick({
    refs: [evaluationPanelRef, iconButtonRef],
    allowUnrenderedRefs: [deletePanelRef],
    func: handleClose,
  });

  const handleDeleteSessionRunDialogClose = useCallback(
    () => setSessionRunToDelete(undefined),
    []
  );

  const handleConfirmDeleteSessionRun = useCallback(async () => {
    try {
      if (!sessionRunToDelete) {
        return;
      }
      await deleteSessionRun(sessionRunToDelete.cid);
      await refetch();
      handleClose();
    } catch (err) {
      console.error(err);
    }
    setSessionRunToDelete(undefined);
  }, [deleteSessionRun, handleClose, refetch, sessionRunToDelete]);

  const popoverState = usePopupState({
    variant: 'popover',
    popupId: null,
  });
  const orderedSessionRuns = useMemo(
    () =>
      orderBy(session.sessionRuns || [], ({ createdAt }) => createdAt, 'desc'),
    [session.sessionRuns]
  );

  const updateSessionRunName = useCallback(
    async (newName: string | undefined, sessionRunData: SessionRunData) => {
      await api.updateSessionRunName({
        cid: sessionRunData?.cid,
        name: newName || '',
        projectId: session.projectId,
      });
      await refetch();
    },
    [refetch, session.projectId]
  );

  if (!orderedSessionRuns?.length) {
    return null;
  }

  return (
    <Popover
      {...bindPopover(popoverState)}
      open={isOpen}
      anchorEl={iconButtonRef.current}
      anchorOrigin={{ vertical: -60, horizontal: 75 }}
      disablePortal={true}
      classes={{
        paper: 'flex max-h-[16rem] overflow-y-auto rounded-lg',
      }}
    >
      <div
        ref={evaluationPanelRef}
        className="pointer-events-auto z-50 flex min-w-[15rem] flex-col py-4 space-y-4 rounded-lg bg-gray-800 border border-solid border-white/10"
      >
        {orderedSessionRuns?.map((SessionRunData, index) => (
          <React.Fragment key={index}>
            <div className="flex flex-row h-4 items-center pl-2">
              <EditableInputCell
                value={SessionRunData.name}
                onChange={(value) =>
                  updateSessionRunName(value, SessionRunData)
                }
                textWhenNotEditable={true}
              />
              <IconButton
                className="h-10 w-10 -top-0.5"
                onClick={() => setSessionRunToDelete(SessionRunData)}
              >
                <Trash />
              </IconButton>

              <AddToDashboardToggle sessionRun={SessionRunData} />
            </div>
            {showDivider(index) && <Divider orientation="horizontal" />}
          </React.Fragment>
        ))}
        <ConfirmDialog
          ref={deletePanelRef}
          title="Are you sure you want to delete this session run?"
          isOpen={!!sessionRunToDelete}
          onClose={handleDeleteSessionRunDialogClose}
          onConfirm={handleConfirmDeleteSessionRun}
        />
      </div>
    </Popover>
  );
}

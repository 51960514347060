import { SampleIdentity } from '@tensorleap/api-client';
import { BaseContainerWrapperProps } from '../dashlet/VisualizationDisplay/types';
import { Tooltip } from '@material-ui/core';
import { VisualizeIcon, XCloseIcon2 } from '../../ui/icons';

export interface PopulationExplorationPreviewWrapperProps
  extends BaseContainerWrapperProps {
  sampleIdentity: SampleIdentity;
  removeSample: (sampleToRemove: SampleIdentity) => void;
  deselectSample?: (sample: SampleIdentity) => void;
}
export function PopulationExplorationPreviewWrapper({
  sampleIdentity,
  children,
  removeSample,
  deselectSample,
}: PopulationExplorationPreviewWrapperProps): JSX.Element {
  return (
    <div className="flex flex-col w-full h-full pt-1 overflow-hidden">
      <div className="flex flex-row justify-between">
        <div className="flex flex-none">Sample #{sampleIdentity.index}</div>

        <div className="flex flex-row gap-1">
          {deselectSample && (
            <Tooltip title="Hide sample">
              <div
                onClick={() => deselectSample(sampleIdentity)}
                className="focus:outline-none cursor-pointer"
              >
                <VisualizeIcon className="w-5 h-5 text-gray-400 hover:text-white" />
              </div>
            </Tooltip>
          )}
          <Tooltip title="Remove sample">
            <div
              onClick={() => removeSample(sampleIdentity)}
              className="focus:outline-none cursor-pointer"
            >
              <XCloseIcon2 className="w-5 h-5 text-gray-400 hover:text-white" />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="flex h-full overflow-y-auto overflow-x-auto">
        {children}
      </div>
    </div>
  );
}

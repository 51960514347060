import { useCallback, useEffect, useMemo, useState } from 'react';
import { DatasetVersion } from '@tensorleap/api-client';
import { PYTHON_DEFAULT_ENTRY_FILE } from './consts';
import { fetchFileMap } from './helper-functions';
import { Setter } from '../../../core/types';
import { useMapTeamStorageUrl } from '../../../core/useProjectStorage';
import api from '../../../core/api-client';

export type FileMap = Record<string, string>;

export type VirtualFS = {
  fileMap: FileMap;
  setFileMap: Setter<FileMap>;
  originalFileMap: FileMap;
  currentFileName: string;
  setCurrentFileName: Setter<string>;
  getDefaultFileName: () => string;
  entryFile: string;
  setEntryFile: Setter<string>;
  datasetVersion: DatasetVersion | undefined;
  secretManagerId: string | undefined;
  setSecretManagerId: Setter<string | undefined>;
  showFiletree: boolean;
  setShowFiletree: Setter<boolean>;
  resetCurrentFileName: () => void;
  hasUnsavedCode: boolean;
  isLoading: boolean;
  getDatasetVersionFileMap: (datasetVersionId: string) => Promise<FileMap>;
  switchDatasetVersion: (version?: DatasetVersion) => void;
};
export function useVirtualFS(): VirtualFS {
  const { mapUrl } = useMapTeamStorageUrl();
  const [datasetVersion, setDatasetVersion] = useState<DatasetVersion>();
  const [isLoading, setIsLoading] = useState(true);

  const { blobPath, codeEntryFile = PYTHON_DEFAULT_ENTRY_FILE } =
    datasetVersion || {};

  const [entryFile, setEntryFile] = useState(codeEntryFile);
  useEffect(() => {
    setEntryFile(codeEntryFile);
  }, [codeEntryFile]);

  const [fileMap, setFileMap] = useState<FileMap>({});
  const [originalFileMap, setOriginalFileMap] = useState<FileMap>({});

  const getDatasetVersionFileMap = useCallback(
    async (datasetVersionId: string) => {
      const { datasetVersion } = await api.getDatasetVersion({
        datasetVersionId,
      });

      const fullBlobUrl = mapUrl(datasetVersion.blobPath);
      const fileMap = await fetchFileMap(fullBlobUrl);
      return fileMap;
    },
    [mapUrl]
  );

  const createFileMaps = useCallback(
    async (url: string | undefined): Promise<void> => {
      if (url) {
        const fullBlobUrl = mapUrl(url);
        const fileNameToContent = await fetchFileMap(fullBlobUrl);
        setFileMap(fileNameToContent);
        setOriginalFileMap(fileNameToContent);
      } else {
        setFileMap({});
        setOriginalFileMap({});
      }
    },
    [mapUrl]
  );
  useEffect(() => {
    createFileMaps(blobPath);
  }, [blobPath, createFileMaps]);
  const fileNames = useMemo(() => Object.keys(fileMap), [fileMap]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  const getDefaultFileName = useCallback(() => {
    return (
      fileNames.find((name) => name === codeEntryFile) ||
      fileNames.at(0) ||
      PYTHON_DEFAULT_ENTRY_FILE
    );
  }, [codeEntryFile, fileNames]);
  const [currentFileName, setCurrentFileName] = useState('');

  useEffect(() => {
    if (!currentFileName || !fileNames.includes(currentFileName)) {
      setCurrentFileName(getDefaultFileName);
    }
  }, [currentFileName, fileMap, fileNames, getDefaultFileName]);

  const [secretManagerId, setSecretManagerId] = useState<string | undefined>();

  const [showFiletree, setShowFiletree] = useState(true);

  const resetCurrentFileName = useCallback(() => {
    setCurrentFileName(getDefaultFileName);
  }, [getDefaultFileName, setCurrentFileName]);

  const switchDatasetVersion = useCallback(
    (version: DatasetVersion | undefined) => {
      setDatasetVersion(version);
      setSecretManagerId(version?.metadata.secretManagerId);
    },
    [setDatasetVersion, setSecretManagerId]
  );

  const hasUnsavedCode = useMemo<boolean>(() => {
    const isSecretManagerIdChanged =
      secretManagerId !== datasetVersion?.metadata.secretManagerId;

    const isEntryFileChanged =
      datasetVersion !== undefined &&
      entryFile !== datasetVersion?.codeEntryFile;

    const isFileMapChanged =
      Object.keys(fileMap).length !== Object.keys(originalFileMap).length ||
      Object.entries(fileMap).some(
        ([filename, content]) => originalFileMap[filename] !== content
      );
    return isSecretManagerIdChanged || isEntryFileChanged || isFileMapChanged;
  }, [datasetVersion, entryFile, fileMap, originalFileMap, secretManagerId]);

  return useMemo<VirtualFS>(
    () => ({
      fileMap,
      setFileMap,
      originalFileMap,
      currentFileName,
      setCurrentFileName,
      getDefaultFileName,
      entryFile,
      setEntryFile,
      datasetVersion,
      secretManagerId,
      setSecretManagerId,
      showFiletree,
      setShowFiletree,
      resetCurrentFileName,
      hasUnsavedCode,
      isLoading,
      getDatasetVersionFileMap,
      switchDatasetVersion,
    }),
    [
      fileMap,
      originalFileMap,
      currentFileName,
      getDefaultFileName,
      entryFile,
      datasetVersion,
      secretManagerId,
      showFiletree,
      resetCurrentFileName,
      hasUnsavedCode,
      isLoading,
      getDatasetVersionFileMap,
      switchDatasetVersion,
    ]
  );
}

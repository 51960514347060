import clsx from 'clsx';
import {
  FILTER_LABEL_ICON,
  LabelOption,
  MultiSelectIconMenu,
  renderLabelOption,
} from './atoms/MultiSelectIconMenu';
import { useCallback, useMemo, useState } from 'react';
import { labelsColorSupplier } from '../core/color-helper';

export interface TextWithSegmentationProps {
  text: string[];
  labels: string[];
  mask: number[];
  className?: string;
  textClassName?: string;
}

export function TextWithSegmentation({
  text,
  labels,
  mask,
  className,
  textClassName,
}: TextWithSegmentationProps): JSX.Element {
  const [ignoredLabels, setIgnoredLabels] = useState<string[]>([]);

  const labelOptions = useMemo(
    () =>
      labels.map((label) => ({
        label,
        value: label,
        color: labelsColorSupplier.get(label),
      })),
    [labels]
  );

  const ignoredLabelsSet = useMemo(() => new Set(ignoredLabels), [
    ignoredLabels,
  ]);

  const getColorIfNeeded = useCallback(
    (label: string, opacity = 1) =>
      ignoredLabelsSet.has(label)
        ? ''
        : labelsColorSupplier.getWithOpacity(label, opacity),
    [ignoredLabelsSet]
  );

  return (
    <div className={clsx('flex-1 flex-col gap-1 justify-center', className)}>
      <div className="flex justify-end">
        <MultiSelectIconMenu<LabelOption, string>
          icon={FILTER_LABEL_ICON}
          iconWrapperClassName="m-2 h-8 w-8"
          invertedSelection
          options={labelOptions}
          value={ignoredLabels}
          onChange={setIgnoredLabels}
          renderOption={renderLabelOption}
        />
      </div>

      <div
        className={clsx(
          'flex flex-wrap gap-1 text-base break-words',
          textClassName
        )}
      >
        {text.map((word, index) => (
          <span
            key={index}
            style={{
              color: getColorIfNeeded(labels[mask[index]]),
              backgroundColor: getColorIfNeeded(labels[mask[index]], 0.1),
            }}
          >
            {word}
          </span>
        ))}
      </div>
    </div>
  );
}

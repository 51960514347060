import {
  ComponentType,
  ReactNode,
  Ref,
  forwardRef,
  useCallback,
  useMemo,
} from 'react';
import {
  AsyncOption,
  buidDefaultLabelMap,
  mapValue,
  OptionType,
  useAsyncOptions,
} from './utils/select';
import { CircularProgress, InputAdornment, TextField } from '@material-ui/core';
import { Autocomplete } from '../mui';
import { Down, XCloseIcon2 } from '../icons';
import { Chip } from './Chip';
import clsx from 'clsx';
import { InputNote } from './utils/InputNote';
import { NumberOrString } from '@tensorleap/api-client';
import { AutocompleteChangeReason } from '@material-ui/lab';

export type SelectMultipleProps<Option = OptionType> = {
  label?: string;
  options: AsyncOption<Option>;
  value: NumberOrString[];
  noOptionsText?: ReactNode;
  disabled?: boolean;
  className?: string;
  onChange: (
    value: NumberOrString[],
    selectedOptions: Option[]
  ) => void | Promise<void>;
  optionToLabel?: (_: Option) => string;
  renderValue?: (
    value: NumberOrString[],
    options: Option[],
    removeFunc?: (_: Option) => void
  ) => ReactNode;
  queryToOption?: (query: string) => Option; // in case of selecting an option that is not in the list
  optionID?: keyof Option & string;
  error?: string;
  placeholder?: string;
  listPaperClassName?: string;
  small?: boolean;
  readonly?: boolean;
};

export function SelectMultipleInner<Option>(
  {
    label,
    error,
    value = [],
    onChange,
    options,
    optionID = 'value' as keyof Option & string,
    optionToLabel,
    queryToOption,
    renderValue,
    className,
    listPaperClassName,
    noOptionsText,
    readonly,
    disabled,
    placeholder,
    small,
  }: SelectMultipleProps<Option>,
  ref: Ref<unknown>
) {
  const { loadedOptions, setQuery, loading } = useAsyncOptions(options);
  const currentOptions = Array.isArray(options) ? options : loadedOptions;
  const optionByValue = useMemo(() => {
    return Object.fromEntries(
      currentOptions.map((option) => [mapValue(option, optionID), option])
    );
  }, [optionID, currentOptions]);

  const selectedOptions = useMemo(
    () =>
      value
        .map(
          (value) =>
            optionByValue[value] ?? queryToOption?.(String(value ?? ''))
        )
        .filter((v): v is Option => v !== undefined),
    [value, optionByValue, queryToOption]
  );

  const optionToLabelWrapper = useMemo(
    () => (optionToLabel ? optionToLabel : buidDefaultLabelMap(optionID)),
    [optionToLabel, optionID]
  );

  const onChangeWrapper = useCallback(
    (_: unknown, valueOrItem: unknown[], reason: AutocompleteChangeReason) => {
      let values: NumberOrString[] = [];
      let items: Option[] = [];
      if (reason === 'create-option') {
        items = valueOrItem.splice(0, valueOrItem.length - 1) as Option[];
        queryToOption &&
          items.push(queryToOption(String(valueOrItem[0] ?? '')) as Option);
      } else {
        items = valueOrItem as Option[];
      }
      values = items.map((item) => mapValue(item, optionID));
      onChange(values, items as Option[]);
    },
    [onChange, optionID, queryToOption]
  );

  const handleChipDelete = useCallback(
    (option: Option) =>
      onChangeWrapper(
        undefined,
        selectedOptions.filter((v) => v !== option),
        'remove-option'
      ),
    [selectedOptions, onChangeWrapper]
  );

  const renderValueWrapper = useCallback(() => {
    const removeFunc = disabled || readonly ? undefined : handleChipDelete;
    if (renderValue) {
      return renderValue(value, selectedOptions, removeFunc);
    }
    return selectedOptions.map((option, i) => (
      <Chip key={value[i]}>
        {optionToLabelWrapper(option)}
        {removeFunc && (
          <XCloseIcon2
            className="ml-2 text-sm cursor-pointer"
            onClick={() => removeFunc(option)}
          />
        )}
      </Chip>
    ));
  }, [
    renderValue,
    selectedOptions,
    optionToLabelWrapper,
    handleChipDelete,
    value,
    disabled,
    readonly,
  ]);

  const optionIsObj = typeof currentOptions[0] === 'object';

  const endAdornment = loading ? (
    <InputAdornment position="end">
      <CircularProgress size={20} />
    </InputAdornment>
  ) : undefined;

  return (
    <div className={clsx('flex flex-col', className)}>
      <Autocomplete
        multiple
        freeSolo={!!queryToOption}
        popupIcon={<Down />}
        loading={loading}
        ref={ref}
        disabled={disabled || readonly}
        className="w-full"
        classes={{ popper: 'z-[1500]', paper: listPaperClassName }}
        value={selectedOptions}
        options={currentOptions}
        renderTags={renderValueWrapper}
        getOptionLabel={optionToLabelWrapper}
        disableCloseOnSelect
        noOptionsText={noOptionsText}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              className: clsx(
                small ? 'py-1' : 'py-2',
                'flex gap-2 flex-wrap py-2'
              ),
              endAdornment: endAdornment ?? params.InputProps.endAdornment,
            }}
            inputProps={{
              ...params.inputProps,
              className: 'flex-1 py-1',
            }}
            InputLabelProps={{
              ...params.InputLabelProps,
              className: clsx(
                small ? 'translate-y-[11px]' : 'translate-y-[15px]',
                'translate-x-[14px]'
              ),
            }}
            variant="outlined"
            error={!!error}
            placeholder={placeholder}
          />
        )}
        itemID={optionIsObj ? optionID : undefined}
        onChange={onChangeWrapper}
        onInputChange={(_, newQuery) => {
          setQuery(newQuery);
        }}
      />
      <InputNote error>{error}</InputNote>
    </div>
  );
}

// There is a problem to use forwardRef with generic props so this is good enough workaround
export const SelectMultiple = forwardRef(SelectMultipleInner) as <Option>(
  props: SelectMultipleProps<Option>
) => JSX.Element;
(SelectMultiple as ComponentType).displayName = 'SelectMultiple';

import { useCallback } from 'react';
import { Dataset, DatasetVersion } from '@tensorleap/api-client';
import { useDatasets } from '../../core/DatasetsContext';
import { DatasetVersionProperties } from '../../layer-details/DatasetVersionProperties';
import {
  NodeDetailsPanel,
  NoPreview,
} from '../../layer-details/NodeDetalisPanel';
import { MiniDocIcon, MoreUp, Settings } from '../../ui/icons';
import { DatasetVersionsPopover } from '../../ui/molecules/DatasetVersionsPopover';
import { Select } from '../../ui/atoms/Select';
import { Button } from '../../ui/atoms/Button';
import { Tooltip } from '@material-ui/core';
import { useNetworkMapContext } from '../../core/NetworkMapContext';
import { ValidateAssetsButton } from '../../ui/molecules/ValidateAssetsButton';
import { useCurrentProject } from '../../core/CurrentProjectContext';
import { CodeIntegrationEditor } from './CodeIntegrationEditor';
import clsx from 'clsx';
import { useMappingUtils } from '../../core/mappingUtils';
import {
  useMappingCreateOrUpdateText,
  useMappingCreateOrUpdateTooltip,
  NO_DATASET_MAPPING_MESSAGE,
  DATASET_MAPPING_MISSMATCH_MESSAGE,
  APPLY_BUTTON_TOOLTIP,
  APPLY_BUTTON_TEXT,
} from '../../network-editor/wizard/cards/DatasetMappingCreateUpdateApplyData';

export const UPDATE_TO_LATEST_DS_VERSION =
  'Update to latest valid dataset version';

export interface MapCodeViewProps {
  connectedDatasetVersion?: DatasetVersion;
  handleConnectedDatasetVersionChange: (
    dataset: Dataset,
    datasetVersion?: DatasetVersion | undefined
  ) => void;
}
export function MapCodeView({
  connectedDatasetVersion,
  handleConnectedDatasetVersionChange,
}: MapCodeViewProps): JSX.Element {
  const {
    virtualFs: { hasUnsavedCode },
    dataset,
    switchDatasetVersion,
    datasetMappingFileExists,
    networkAndDatasetMappingsAreEqual,
    datasetsMap,
    datasets,
    datasetVersions,
    fetchDatasetVersions,
    suggestUpdateToLatestVersion,
  } = useDatasets();

  const { addOrUpdateNetworkMapping, applyDatasetMapping } = useMappingUtils();

  const { codeIntegrationIsExpanded } = useNetworkMapContext();

  const {
    currentModelGraph,
    selectedCodeIntegrationVersion,
  } = useCurrentProject();

  const handleConnectedDatasetChange = useCallback(
    async (_, changedDataset: Dataset | null) => {
      if (!changedDataset) {
        console.error(
          'Failed to switch connected dataset since the required dataset object was not found',
          datasetsMap
        );
        return;
      }

      const changedDatasetVersion = changedDataset.latestValidVersion;

      handleConnectedDatasetVersionChange(
        changedDataset,
        changedDatasetVersion
      );
    },
    [datasetsMap, handleConnectedDatasetVersionChange]
  );

  const onConnectedDatasetVersionChange = useCallback(
    (id: string) => {
      const datasetVersion = datasetVersions.find(({ cid }) => cid === id);
      if (!datasetVersion) {
        console.error(
          'Failed to find datasetVersion according to the event datasetVersionId',
          id
        );
        return;
      }

      switchDatasetVersion(datasetVersion);

      const dataset = datasetsMap?.get(datasetVersion.datasetId);
      if (!dataset) {
        console.error(
          'Failed to switch connected datasetVersion since the required datasetId was not found',
          id,
          datasetsMap,
          datasetVersion
        );
        return;
      }

      handleConnectedDatasetVersionChange(dataset, datasetVersion);
    },
    [
      datasetVersions,
      datasetsMap,
      handleConnectedDatasetVersionChange,
      switchDatasetVersion,
    ]
  );

  const isConnectedVersionValid =
    connectedDatasetVersion?.testStatus === 'testSuccess';

  const updateToLatestDatasetVersion = useCallback(() => {
    const latestVersion = dataset?.latestValidVersion;
    if (!latestVersion) {
      console.error(
        'Tried to update codeIntegraion to latest but somehow latest version is undefined'
      );
      return;
    }
    onConnectedDatasetVersionChange(latestVersion.cid);
  }, [dataset?.latestValidVersion, onConnectedDatasetVersionChange]);
  const createOrUpdateText = useMappingCreateOrUpdateText();
  const createOrUpdateTooltip = useMappingCreateOrUpdateTooltip();

  return (
    <div className="h-full w-full flex flex-row-reverse bg-gray-900">
      {/* todo: We have wired situation that cause the hasUnsavedCode to be true even if there is no change need more investigation */}
      {/* <Prompt
        when={hasUnsavedCode}
        message="Leaving will discard your unsaved changes"
      /> */}

      <div className="flex w-80 flex-col overflow-y-auto">
        <NodeDetailsPanel
          className="p-4 gap-8"
          title="Settings"
          icon={<Settings />}
          openByDefault
        >
          <Select
            label="SELECTED SCRIPTS"
            optionID="cid"
            value={dataset?.cid}
            optionToLabel={(option) => option?.name || 'None'}
            options={datasets}
            onChange={handleConnectedDatasetChange}
          />

          {dataset && (
            <div className="flex w-full flex-row justify-end">
              <div className={clsx(!suggestUpdateToLatestVersion && 'w-full')}>
                <DatasetVersionsPopover
                  currentDatasetVersion={selectedCodeIntegrationVersion}
                  hideNonPassedVersionsByDefault
                  handleDatasetVersionIdSelected={
                    onConnectedDatasetVersionChange
                  }
                  datasetVersions={datasetVersions}
                  allowRenameVersions={false}
                  fetchDatasetVersions={fetchDatasetVersions}
                  label={'SELECTED VERSION'}
                />
              </div>

              {suggestUpdateToLatestVersion && (
                <div className="pl-2">
                  <Tooltip title={UPDATE_TO_LATEST_DS_VERSION}>
                    <Button
                      className="w-6"
                      onClick={updateToLatestDatasetVersion}
                    >
                      <MoreUp />
                    </Button>
                  </Tooltip>
                </div>
              )}
            </div>
          )}

          {!isConnectedVersionValid && (
            <span className="text-error-500 px-2 -mt-6">
              Please select a valid version
            </span>
          )}
        </NodeDetailsPanel>
        {isConnectedVersionValid && (
          <>
            <ValidateAssetsButton />
            {(!datasetMappingFileExists ||
              !networkAndDatasetMappingsAreEqual) && (
              <div className="flex flex-col justify-center items-center py-4 gap-2 border-b border-solid border-white/20">
                <span className="flex w-full h-fit text-sm text-gray-350 px-4">
                  {!datasetMappingFileExists
                    ? NO_DATASET_MAPPING_MESSAGE
                    : DATASET_MAPPING_MISSMATCH_MESSAGE}
                </span>
                <div className="flex w-full flex-row gap-4 justify-end px-4">
                  <Tooltip title={createOrUpdateTooltip}>
                    <Button
                      className="h-8"
                      variant="inverted-gray"
                      onClick={() => addOrUpdateNetworkMapping()}
                    >
                      {createOrUpdateText}
                    </Button>
                  </Tooltip>
                  {datasetMappingFileExists &&
                    !networkAndDatasetMappingsAreEqual &&
                    currentModelGraph && (
                      <Tooltip title={APPLY_BUTTON_TOOLTIP}>
                        <Button
                          className="h-8"
                          variant="inverted-gray"
                          onClick={applyDatasetMapping}
                        >
                          {APPLY_BUTTON_TEXT}
                        </Button>
                      </Tooltip>
                    )}
                </div>
              </div>
            )}
            <NodeDetailsPanel
              title="Assets"
              icon={<MiniDocIcon />}
              openByDefault
            >
              {selectedCodeIntegrationVersion ? (
                <DatasetVersionProperties
                  datasetSetup={selectedCodeIntegrationVersion.metadata.setup}
                  modelSetup={
                    selectedCodeIntegrationVersion.metadata.modelSetup
                  }
                />
              ) : (
                <NoPreview />
              )}
            </NodeDetailsPanel>
          </>
        )}
      </div>

      {codeIntegrationIsExpanded && (
        <CodeIntegrationEditor datasetVersionsPopoverLabel={'EDITOR VERSION'} />
      )}
    </div>
  );
}

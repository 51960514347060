import {
  forwardRef,
  ImgHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useCurrentProject } from './CurrentProjectContext';
import { useAuth } from '../auth/AuthContext';
import { useEnvironmentInfo } from './EnvironmentInfoContext';
import clsx from 'clsx';
import { MousePosition } from './useSelectionGroup';

interface UseStorageUrl {
  mapUrl: (path: string) => string;
}

function calcTeamPath(teamId: string): string {
  return `organizations/${teamId}`;
}

function calcProjectPath(projectId: string): string {
  return `projects/${projectId}`;
}

type CalcEpochPathParams = {
  versionId: string;
  sessionId: string;
  sessionRunId: string;
  epoch: number;
};

export function calcEpochPath({
  versionId,
  sessionId,
  sessionRunId,
  epoch,
}: CalcEpochPathParams) {
  return `versions/${versionId}/sessions/${sessionId}/sessionruns/${sessionRunId}/epochs/${epoch}`;
}

export interface CalcFullTeamPathParams {
  clientStoragePrefixUrl: string;
  teamId: string;
}

export function calcFullTeamPath({
  clientStoragePrefixUrl,
  teamId,
}: CalcFullTeamPathParams): string {
  const teamPath = calcTeamPath(teamId);
  return `${clientStoragePrefixUrl}/${teamPath}`;
}

export function trimStorageUrlProject(url: string): string {
  return url.replace(/^.*?\/projects\/[^/]+\/?/, '');
}

export interface CalcFullProjectPathParams {
  clientStoragePrefixUrl: string;
  teamId: string;
  projectId: string;
}
export function calcFullProjectPath({
  clientStoragePrefixUrl,
  teamId,
  projectId,
}: CalcFullProjectPathParams): string {
  const fullTeamPath = calcFullTeamPath({
    clientStoragePrefixUrl,
    teamId,
  });
  const projectPath = calcProjectPath(projectId);
  return `${fullTeamPath}/${projectPath}`;
}

export function useMapProjectStorageUrl(): UseStorageUrl {
  const {
    environmentInfo: { clientStoragePrefixUrl },
  } = useEnvironmentInfo();
  const { currentProjectId, currentProjectTeamId } = useCurrentProject();

  const urlPrefix = useMemo(() => {
    if (!currentProjectId) {
      throw new Error('currentProjectId is not defined');
    }

    return calcFullProjectPath({
      clientStoragePrefixUrl,
      teamId: currentProjectTeamId,
      projectId: currentProjectId,
    });
  }, [clientStoragePrefixUrl, currentProjectId, currentProjectTeamId]);

  const mapUrl = useCallback(
    (path: string) => {
      if (!path.includes('/')) {
        return path;
      }

      return `${urlPrefix}/${path}`;
    },
    [urlPrefix]
  );

  return { mapUrl };
}

export function useMapTeamStorageUrl(): UseStorageUrl {
  const {
    environmentInfo: { clientStoragePrefixUrl },
  } = useEnvironmentInfo();
  const { user } = useAuth();
  const teamId = user?.local.teamId ?? '';

  const urlPrefix = useMemo(
    () =>
      calcFullTeamPath({
        clientStoragePrefixUrl,
        teamId,
      }),
    [clientStoragePrefixUrl, teamId]
  );

  const mapUrl = useCallback(
    (path: string) => {
      return `${urlPrefix}/${path}`;
    },
    [urlPrefix]
  );

  return { mapUrl };
}

export function useMapStoredProjectImgUrl<Src extends string | undefined>(
  pathOrSrc: Src
): Src {
  const { mapUrl } = useMapProjectStorageUrl();
  const src = useMemo(() => {
    if (!pathOrSrc || pathOrSrc.startsWith('data:')) {
      return pathOrSrc;
    }
    return mapUrl(pathOrSrc) as Src;
  }, [mapUrl, pathOrSrc]);
  return src;
}

interface PixelInfo {
  r: number;
  g: number;
  b: number;
}

export interface ImagePixelReaderProps {
  pathOrSrc: string;
  mousePosition: MousePosition;
}

export function ImagePixelReader({
  pathOrSrc,
  mousePosition,
}: ImagePixelReaderProps): JSX.Element {
  const [showDialog, setShowDialog] = useState(false);
  const src = useMapStoredProjectImgUrl(pathOrSrc);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [pixelInfo, setPixelInfo] = useState<PixelInfo>({
    r: 0,
    g: 0,
    b: 0,
  });

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    if (ctx && src) {
      const image = new Image();
      image.src = src;
      image.onload = () => {
        if (canvas) {
          canvas.width = image.width;
          canvas.height = image.height;
          ctx.drawImage(image, 0, 0);
        }
      };
    }
  }, [src]);

  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    setShowDialog(true);
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d', { willReadFrequently: true });

    if (canvas && ctx) {
      const rect = canvas.getBoundingClientRect();

      if (rect.width === 0 || rect.height === 0) return;
      const x = ((e.clientX - rect.left) / rect.width) * canvas.width;
      const y = ((e.clientY - rect.top) / rect.height) * canvas.height;
      const imageData = ctx.getImageData(x, y, 1, 1).data;
      setPixelInfo({
        r: imageData[0],
        g: imageData[1],
        b: imageData[2],
      });
    }
  };

  return (
    <div className="bg-gray-900 flex-1 flex flex-row justify-center h-full w-full object-contain m-auto">
      <canvas
        ref={canvasRef}
        onMouseMove={handleMouseMove}
        onMouseLeave={() => setShowDialog(false)}
        className="inline-block cursor-pixel max-w-full"
      />

      <div
        className={clsx(
          'fixed flex flex-row gap-2 w-full object-contain pointer-events-none bg-transparent drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] overflow-visible',
          !showDialog && 'hidden'
        )}
        style={{
          top: mousePosition.y + 12,
          left: mousePosition.x + 14,
        }}
      >
        <div
          className="w-5 h-5 border-white border-[1px] overflow-visible"
          style={{
            backgroundColor: `rgb(${pixelInfo.r}, ${pixelInfo.g}, ${pixelInfo.b})`,
          }}
        />
        <div className="flex flex-col overflow-visible -mt-5">
          <p>R: {pixelInfo.r}</p>
          <p>G: {pixelInfo.g}</p>
          <p>B: {pixelInfo.b}</p>
        </div>
      </div>
    </div>
  );
}

export const StoredProjectImg = forwardRef<
  HTMLImageElement,
  ImgHTMLAttributes<HTMLImageElement>
>(({ src: pathOrSrc, ...props }, ref) => {
  const src = useMapStoredProjectImgUrl(pathOrSrc);
  return <img src={src} ref={ref} {...props} />;
});

StoredProjectImg.displayName = 'StoredProjectImg';

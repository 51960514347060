import { useEffect, useState } from 'react';
import { useMapStoredProjectImgUrl } from '../../core/useProjectStorage';
import clsx from 'clsx';
import { interpolateTurbo, scaleSequential } from 'd3';
import { HeatmapSliders } from '../../dashboard/SampleAnalysisView/HeatmapSliders';
import { useFetchFlatbuffers } from '../../core/data-fetching/fetch-flat-buffer';
import * as flatbuffers from 'flatbuffers';
import { HeatmapData } from './flatbuffer/heatmap-data';
import { debounce } from 'lodash';

interface ImageWithHeatmapProps {
  srcBlob: string;
  heatmapBlob: string;
  showSliders?: boolean;
  className?: string;
}

export function ImageWithHeatmap({
  srcBlob,
  heatmapBlob,
  showSliders = true,
  className,
}: ImageWithHeatmapProps): JSX.Element {
  const [clipping, setClipping] = useState<number[] | undefined>([0.05, 0.95]);
  const [opacity, setOpacity] = useState<number | undefined>(0.75);
  const opacitySlideSelectorProps = {
    value: opacity,
    setValue: setOpacity,
  };
  const clippingSlideSelectorProps = {
    value: clipping,
    setValue: setClipping,
  };

  const mappedImgSrc = useMapStoredProjectImgUrl(srcBlob);
  const mappedHeatmapBlob = useMapStoredProjectImgUrl(heatmapBlob);

  const postProcessFlatbuffer = (
    buffer: flatbuffers.ByteBuffer
  ): number[][] => {
    const heatmapData = HeatmapData.getRootAsHeatmapData(buffer);
    const width = heatmapData.width();
    const height = heatmapData.height();
    const flatArray = heatmapData.dataArray();
    if (!flatArray) {
      return [];
    }

    const heatmap = new Array(height)
      .fill(null)
      .map(() => new Array(width).fill(0));

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        heatmap[y][x] = flatArray[y * width + x];
      }
    }
    return heatmap;
  };

  const { data: heatmap } = useFetchFlatbuffers<number[][]>({
    url: mappedHeatmapBlob,
    postProcess: postProcessFlatbuffer,
  });

  const [canvasUrl, setCanvasUrl] = useState<string | null>(null);

  const updateHeatmapVisualization = debounce(() => {
    const hasNoData =
      !srcBlob || !heatmap || !heatmap.length || !heatmap[0].length;
    if (hasNoData) return;

    const debounceDelay = 50;
    const debounce = setTimeout(() => {
      let minValue = Infinity;
      let maxValue = -Infinity;

      for (let y = 0; y < heatmap.length; y++) {
        for (let x = 0; x < heatmap[y].length; x++) {
          const value = heatmap[y][x];
          if (value < minValue) minValue = value;
          if (value > maxValue) maxValue = value;
        }
      }

      if (clipping) {
        const [minClip, maxClip] = clipping;
        const range = maxValue - minValue;
        minValue += range * minClip;
        maxValue = minValue + range * maxClip;
      }

      const normalizedHeatmap = new Array(heatmap.length);
      for (let y = 0; y < heatmap.length; y++) {
        normalizedHeatmap[y] = new Array(heatmap[y].length);
        for (let x = 0; x < heatmap[y].length; x++) {
          const value = heatmap[y][x];
          const clippedValue = Math.min(Math.max(value, minValue), maxValue);
          normalizedHeatmap[y][x] =
            (clippedValue - minValue) / (maxValue - minValue);
        }
      }
      const colorScale = scaleSequential(interpolateTurbo).domain([0, 1]);

      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        canvas.width = img.width;
        canvas.height = img.height;

        const xScale = img.width / normalizedHeatmap[0].length;
        const yScale = img.height / normalizedHeatmap.length;

        for (let y = 0; y < normalizedHeatmap.length; y++) {
          for (let x = 0; x < normalizedHeatmap[y].length; x++) {
            const value = normalizedHeatmap[y][x];
            if (value !== 0) {
              ctx.fillStyle = colorScale(value);
              ctx.fillRect(x * xScale, y * yScale, xScale, yScale);
            }
          }
        }
        setCanvasUrl(canvas.toDataURL());
      };
      img.src = mappedImgSrc || '';
    }, debounceDelay);

    return () => clearTimeout(debounce);
  }, 25);

  useEffect(() => {
    updateHeatmapVisualization();
    return () => updateHeatmapVisualization.cancel();
  }, [heatmap, mappedImgSrc, clipping, updateHeatmapVisualization]);

  if (!srcBlob) {
    return <></>;
  }
  return (
    <>
      {showSliders && (
        <HeatmapSliders
          opacitySlideSelectorProps={opacitySlideSelectorProps}
          clippingSlideSelectorProps={clippingSlideSelectorProps}
        />
      )}
      <div className={clsx('relative', className)}>
        <img
          src={mappedImgSrc}
          className="h-full w-fit m-auto object-contain min-h-0"
          alt="Heatmap Background"
        />
        {canvasUrl && (
          <div
            className="absolute top-0 left-0 right-0 bottom-0"
            style={{
              backgroundImage: `url(${canvasUrl})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              opacity,
            }}
          ></div>
        )}
      </div>
    </>
  );
}

import { Session, SlimVersion } from '@tensorleap/api-client';
import { useVersionControl } from '../core/VersionControlContext';
import { Table } from '../ui/model-list/table/Table';
import { ModelFields } from '../ui/model-list/types';
import { CloudDlIcon, DatatypeIcon, Train2Icon, Trash } from '../ui/icons';
import { HoverAction } from '../ui/model-list/table/TableRowActions';
import { useCallback, useMemo, useState } from 'react';
import { RunModelDialog } from '../actions-dialog/RunModel';
import { ExportDialog } from '../actions-dialog/ExportDialog';
import { ConfirmDialog } from '../ui/atoms/DeleteContentDialog';
import { AddToDashboardToggle } from './AddToDashboardToggle';
import { SessionRunsDialog } from './SessionRunsDialog';
import { sessionHasTrainedEpochs } from '../actions-dialog/helper-functions';
import { orderBy } from 'lodash';
import { EditableInputCell } from '../ui/atoms/EditableInputCell';
import api from '../core/api-client';

type ExpandedVersionRowProps = { item: SlimVersion };

export function ExpandedVersionRow({ item }: ExpandedVersionRowProps) {
  const {
    deleteSession,
    refetch,
    setSessionToShowItsEpochs,
  } = useVersionControl();

  const updateSessionName = useCallback(
    async (newName: string | undefined, session: Session) => {
      await api.updateSessionName({
        cid: session?.cid,
        name: newName || '',
        projectId: session.projectId,
      });
      await refetch();
    },
    [refetch]
  );

  const MODEL_FIELDS: ModelFields<Session> = [
    {
      label: 'sessions',
      table: { headerClassName: 'text-gray-500' },
      format(session: Session) {
        return (
          <EditableInputCell
            value={session.modelName}
            onChange={(value) => updateSessionName(value, session)}
            textWhenNotEditable={true}
            truncateStartSubsetLength={15}
          />
        );
      },
    },
  ];
  const [sessionToExport, setSessionToExport] = useState<Session | null>(null);

  const handleExportDialogClose = useCallback(
    () => setSessionToExport(null),
    []
  );

  const [sessionToTrain, setSessionToTrain] = useState<Session | null>(null);
  const handleTrainDialogClose = useCallback(() => setSessionToTrain(null), []);

  const [sessionToDelete, setSessionToDelete] = useState<Session | null>(null);

  const handleDeleteModelDialogClose = useCallback(
    () => setSessionToDelete(null),
    []
  );

  const handleConfirmDeleteModel = useCallback(async () => {
    try {
      if (sessionToDelete) {
        await deleteSession(sessionToDelete.cid);
      }
    } catch (err) {
      console.error(err);
    }
    setSessionToDelete(null);
  }, [deleteSession, sessionToDelete]);

  const hoverActions = useMemo<HoverAction<Session>[]>(
    () => [
      {
        icon: <DatatypeIcon className="text-gray-300" />,
        title: 'Show epochs',
        filter: (session: Session) => session.hasExternalEpoch,
        onSelect: (session) => setSessionToShowItsEpochs(session),
      },
      {
        icon: <CloudDlIcon className="text-gray-300" />,
        title: 'Export Model',
        filter: sessionHasTrainedEpochs,
        onSelect: setSessionToExport,
      },
      {
        icon: <Train2Icon className="text-gray-300" />,
        title: 'Continue Training',
        filter: sessionHasTrainedEpochs,
        onSelect: setSessionToTrain,
      },
      {
        icon: <Trash className="text-gray-300" />,
        title: 'Delete Session',
        onSelect: setSessionToDelete,
      },
    ],
    [setSessionToShowItsEpochs]
  );

  const [selectedSessionDialog, setSelectedSessionDialog] = useState<
    Session | undefined
  >(undefined);
  const deselectSessionDialog = useCallback(() => {
    setSelectedSessionDialog(undefined);
  }, []);

  const showVcSessionsPopover = useCallback(
    (session: Session) => (session?.sessionRuns?.length ?? 0) > 1,
    []
  );

  const renderToggleModel = useCallback(
    (session: Session) => {
      if (showVcSessionsPopover(session)) {
        return (
          <SessionRunsDialog
            handleClose={deselectSessionDialog}
            session={session}
            selectedSession={selectedSessionDialog}
            setSelectedSession={setSelectedSessionDialog}
          />
        );
      }
      const firstSessionRun = session?.sessionRuns?.[0];
      return <AddToDashboardToggle sessionRun={firstSessionRun} />;
    },
    [deselectSessionDialog, selectedSessionDialog, showVcSessionsPopover]
  );

  const orderedSessions = useMemo(
    () => orderBy(item.sessions, ({ createdAt }) => createdAt, 'desc'),
    [item.sessions]
  );

  return (
    <>
      <Table
        tableBgClass="bg-gray-850"
        headersBgClass="bg-gray-900 !z-0"
        inline
        fields={MODEL_FIELDS}
        data={orderedSessions}
        customActions={renderToggleModel}
        hoverActions={hoverActions}
      />
      {sessionToExport?.sessionWeights?.length && (
        <ExportDialog
          isOpen={true}
          onClose={handleExportDialogClose}
          sessionWeights={sessionToExport.sessionWeights}
        />
      )}
      {sessionToTrain?.sessionWeights?.length && (
        <RunModelDialog
          isOpen={true}
          onClose={handleTrainDialogClose}
          initialVersion={item}
          initialSession={sessionToTrain}
          initialRunModelMethod="Continue"
        />
      )}
      <ConfirmDialog
        title="Are you sure you want to delete this session run?"
        isOpen={!!sessionToDelete}
        onClose={handleDeleteModelDialogClose}
        onConfirm={handleConfirmDeleteModel}
      />
    </>
  );
}

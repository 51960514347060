import { MessageData } from '@tensorleap/api-client';
import { useCallback, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { getApiHost } from './api-client';
import { useKeycloak } from '@react-keycloak/web';
const SERVER_MESSAGE = 'serverMessage';

const WS_PREFIX = document.location.protocol === 'https:' ? 'wss' : 'ws';
export const WS_URL = `${WS_PREFIX}://${getApiHost()}`;

export function useSocketIO(url: string | null) {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [lastMessage, setLastMessage] = useState<MessageData | null>(null);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (!url || !keycloak?.token) {
      return;
    }
    const socket = io(url, {
      withCredentials: true,
      auth: {
        Authorization: `KBearer ${keycloak.token}`,
      },
    });
    setSocket(socket);

    socket.on('disconnect', (reason) => {
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
      console.warn('socket io disconnected, reason:', reason);
      // else the socket will automatically try to reconnect
    });

    socket.on('connect_error', (err) => {
      console.error('socket io connect_error', err.message, err);
    });

    socket.on('authentication_error', (err) => {
      console.error('socket io authentication_error', err.message);
    });

    socket.on(SERVER_MESSAGE, (message) => {
      setLastMessage(message);
    });

    return () => {
      socket.close();
    };
  }, [url, keycloak?.token]);

  const sendMessage = useCallback(
    (message) => {
      if (socket) {
        socket.send(message);
      }
    },
    [socket]
  );

  return { sendMessage, lastMessage };
}

import { useCallback, useRef, useState } from 'react';
import { IconButton, Paper } from '../../ui/mui';

import { Button } from '../../ui/atoms/Button';
import { AddDb, GitIcon, XClose } from '../../ui/icons';
import {
  AnalyzeSample,
  AnalyzeSampleInputs,
  PopulationExploration,
} from './panes';
import { useSelectedSessionRuns } from './panes/useSelectedModels';

export enum Pane {
  ADD_ANALYSIS = 'ADD ANALYSIS',
  POP_EXP = 'POPULATION EXPLORATION',
  ANALYZE_SAMPLE = 'ANALYZE SAMPLE',
}

export interface AddAnalysisPaneProps {
  initPane?: Pane;
  defaultValues?: Partial<AnalyzeSampleInputs>;
  closeTooltip: () => void;
}
export function AddAnalysisPane({
  initPane = Pane.ADD_ANALYSIS,
  defaultValues,
  closeTooltip,
}: AddAnalysisPaneProps): JSX.Element {
  const [pane, setPane] = useState<Pane>(initPane);
  const selectedSessionRuns = useSelectedSessionRuns();

  const handleClose = useCallback(() => {
    const shouldClose =
      pane === Pane.ADD_ANALYSIS || initPane !== Pane.ADD_ANALYSIS;
    if (shouldClose) {
      closeTooltip();
    } else {
      setPane(Pane.ADD_ANALYSIS);
    }
  }, [closeTooltip, pane, initPane]);

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Paper
      variant="outlined"
      className="w-[370px] h-fit p-4 overflow-y-auto"
      ref={inputRef}
    >
      <div className="flex flex-col gap-2 justify-evenly items-stretch h-full">
        <div className="flex flex-row justify-between align-center items-center w-full">
          <span className="font-medium text-sm leading-relaxed uppercase">
            {pane}
          </span>
          <IconButton onClick={handleClose}>
            <XClose />
          </IconButton>
        </div>
        {pane === Pane.ADD_ANALYSIS ? (
          <>
            {selectedSessionRuns.length > 0 ? (
              <div className="flex flex-col flex-1 justify-center items-center">
                <span className="font-medium text-sm leading-relaxed uppercase text-gray-600 text-center">
                  select a dashlet type to add
                </span>
              </div>
            ) : (
              <div className="flex flex-col flex-1 justify-center items-center">
                <span className="font-medium text-sm justify-center leading-relaxed uppercase text-white text-center">
                  select a model <AddDb /> from the version control pane{' '}
                  <GitIcon />
                </span>
              </div>
            )}

            <footer className="flex flex-col gap-2">
              <Button
                disabled={selectedSessionRuns.length === 0}
                onClick={() => setPane(Pane.POP_EXP)}
                className="w-full"
                variant="inverted-gray"
              >
                population exploration
              </Button>
              <Button
                disabled={selectedSessionRuns.length === 0}
                onClick={() => setPane(Pane.ANALYZE_SAMPLE)}
                variant="inverted-gray"
                className="w-full"
              >
                analyze sample
              </Button>
            </footer>
          </>
        ) : pane === Pane.POP_EXP ? (
          <PopulationExploration closeTooltip={closeTooltip} />
        ) : pane === Pane.ANALYZE_SAMPLE ? (
          <AnalyzeSample
            defaultValues={defaultValues}
            closeTooltip={closeTooltip}
          />
        ) : null}
      </div>
    </Paper>
  );
}

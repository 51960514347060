import {
  createContext,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useContext } from 'react';
import { datadogRum } from '@datadog/browser-rum';

import { useMergedObject } from './useMergedObject';
import { useAuth } from '../auth/AuthContext';
import { useSocketIO, WS_URL } from './useSocketIO';
import { useLocalStorage } from './useLocalStorage';
import {
  isJobStatusMessageParams,
  isPushNotificationsMsg,
} from './websocket-message-types';

const browserNotificationsKey = 'browserNotificationsShowWhenNotActive';

export type OnNotificationHandler = (msg: unknown) => void;
export type OnNotification = (handler?: OnNotificationHandler) => void;
export interface ServerMessage {
  source: string;
  payload: unknown;
}
export interface GenericMessageData {
  data: {
    title: string;
    messageData: {
      level: string;
      params: {
        message: string;
      };
    };
    context: {
      projectName: string;
    };
  };
}

export interface PushNotificationsContextInterface {
  lastServerMessage?: ServerMessage['payload'];
  subscribe: (handler?: OnNotificationHandler) => void;
  unsubscribe: (handler?: OnNotificationHandler) => void;
  browserNotificationsState: boolean;
  setBrowserNotificationsState: (state: boolean) => void;
}

export const PushNotificationsContext = createContext<PushNotificationsContextInterface>(
  {
    lastServerMessage: undefined,
    subscribe: () => undefined,
    unsubscribe: () => undefined,
    browserNotificationsState: false,
    setBrowserNotificationsState: () => undefined,
  }
);

export const PushNotificationsProvider: FC = ({ children }) => {
  const { user } = useAuth();

  const [lastServerMessage, setLastServerMessage] = useState<
    ServerMessage['payload']
  >();
  const [serverURL, setServerURL] = useState<string | null>(null);
  const subscriberRef = useRef(new Set<OnNotificationHandler>());

  const { lastMessage } = useSocketIO(serverURL);

  const subscribe = useCallback((handler?: OnNotificationHandler) => {
    handler && subscriberRef.current?.add(handler);
  }, []);

  const unsubscribe = useCallback((handler?: OnNotificationHandler) => {
    handler && subscriberRef.current?.delete(handler);
  }, []);

  const [
    browserNotificationsState,
    setBrowserNotificationsState,
  ] = useLocalStorage(browserNotificationsKey, true);

  useEffect(() => {
    if (Notification.permission === 'default') {
      Notification.requestPermission().then((permission) => {
        if (permission !== 'granted') {
          console.warn('Notification permission not granted');
        }
      });
    }
  }, []);

  useEffect(() => {
    if (user) {
      setServerURL(WS_URL);
    } else {
      setServerURL(null);
    }
  }, [user]);

  const shouldShowNotification = useCallback(
    (message: unknown) => {
      const isJobStatusMessage =
        isPushNotificationsMsg(message) &&
        isJobStatusMessageParams(message.data.messageData.params);
      const isNotInBrowserContext = document.hidden || !document.hasFocus();
      return (
        isJobStatusMessage && browserNotificationsState && isNotInBrowserContext
      );
    },
    [browserNotificationsState]
  );

  const sendNotification = useCallback((message: unknown) => {
    if (Notification.permission !== 'granted') {
      console.warn('Notification permission not granted');
      return;
    }

    try {
      const eventGenericMessage = (message as unknown) as GenericMessageData;
      navigator.serviceWorker.ready
        .then((registration) => {
          if (registration.active) {
            try {
              registration.active.postMessage({
                type: 'SHOW_NOTIFICATION',
                payload: {
                  title:
                    eventGenericMessage?.data?.title ||
                    'Tesorleap event received',
                  options: {
                    body:
                      eventGenericMessage?.data?.messageData?.params?.message ||
                      '',
                    level:
                      eventGenericMessage?.data?.messageData?.level ||
                      'UNSTARTED',
                  },
                },
              });
            } catch (error) {
              console.error('Error posting message to service worker:', error);
            }
          } else {
            console.warn('Service worker is not active');
          }
        })
        .catch((error) => {
          console.error('Error getting service worker registration:', error);
        });
    } catch (error) {
      console.error('Error in sendNotification:', error);
    }
  }, []);

  useEffect(() => {
    let notificationTimeout: NodeJS.Timeout;

    const handleVisibilityChange = () => {
      if (lastMessage && shouldShowNotification(lastMessage)) {
        sendNotification(lastMessage);
      }
    };

    if (lastMessage) {
      setLastServerMessage(lastMessage);
      datadogRum.addAction('server-notification-received', lastMessage);

      if (shouldShowNotification(lastMessage)) {
        sendNotification(lastMessage);
      } else {
        notificationTimeout = setTimeout(() => {
          if (shouldShowNotification(lastMessage)) {
            sendNotification(lastMessage);
          }
        }, 1000);
      }

      for (const handler of Array.from(subscriberRef.current)) {
        try {
          handler(lastMessage);
        } catch (error) {
          console.error('Error in notification handler:', error);
        }
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearTimeout(notificationTimeout);
    };
  }, [
    browserNotificationsState,
    lastMessage,
    sendNotification,
    shouldShowNotification,
  ]);

  const value = useMergedObject({
    lastServerMessage,
    subscribe,
    unsubscribe,
    browserNotificationsState,
    setBrowserNotificationsState,
  });

  return (
    <PushNotificationsContext.Provider value={value}>
      {children}
    </PushNotificationsContext.Provider>
  );
};

export const usePushNotifications = (): PushNotificationsContextInterface =>
  useContext(PushNotificationsContext);

export function useNotificationSubscriber(handler: OnNotificationHandler) {
  const { subscribe, unsubscribe } = usePushNotifications();
  useEffect(() => {
    subscribe(handler);
    return () => unsubscribe(handler);
  }, [subscribe, unsubscribe, handler]);
}

import {
  MutualInformationElement,
  NumberOrString,
} from '@tensorleap/api-client';

import { CorrelatedMetadataOnClusterMsg } from '../../../insights/InsightCardText';
import { LabelCircleSvg } from './CircleSvg';
import { Tooltip } from '@material-ui/core';
import { truncateLongtail } from '../../../core/formatters/string-formatting';
import clsx from 'clsx';
import { ReactNode } from 'react';

export interface ScatterLabelElementProps {
  label: NumberOrString;
  value: NumberOrString;
  handleLegendClick: (label: NumberOrString) => void;
  handleLegendMouseOver: (label: NumberOrString) => void;
  handleLegendMouseLeave: () => void;
  showNames: boolean;
  hiddenLabels?: NumberOrString[];
  truncatedLongtail: number;
  appearances?: Map<NumberOrString, number>;
  clusterData?: Record<string, MutualInformationElement[]>;
  colorMap?: Record<string, string>;
}

export function ScatterLabelElement({
  label,
  value,
  handleLegendClick,
  handleLegendMouseOver,
  handleLegendMouseLeave,
  showNames,
  hiddenLabels,
  truncatedLongtail,
  appearances,
  clusterData,
  colorMap,
}: ScatterLabelElementProps): JSX.Element {
  return (
    <div
      key={value}
      className="flex flex-row-reverse gap-2 items-end cursor-pointer pointer-events-auto"
      onClick={() => handleLegendClick(value)}
      onMouseEnter={() => handleLegendMouseOver(value)}
      onMouseLeave={handleLegendMouseLeave}
    >
      {appearances || clusterData ? (
        <Tooltip
          placement="left"
          title={
            <LabelLegendTooltip
              appearances={appearances}
              clusterData={clusterData}
              label={label}
              value={value}
              icon={<LabelCircleSvg colorMap={colorMap} label={value} />}
            />
          }
        >
          <div>
            {/* Added div for tooltip - tooltip does not display on pure SVG */}
            <LabelCircleSvg colorMap={colorMap} label={value} />
          </div>
        </Tooltip>
      ) : (
        <LabelCircleSvg colorMap={colorMap} label={value} />
      )}
      {showNames && (
        <p className={clsx(hiddenLabels?.includes(value) && 'opacity-20')}>
          {truncateLongtail({
            value: label,
            startSubsetLength: truncatedLongtail,
          })}
        </p>
      )}
    </div>
  );
}

export type LabelLegendTooltipProps = {
  appearances?: Map<NumberOrString, number>;
  clusterData?: Record<string, MutualInformationElement[]>;
  label: NumberOrString;
  icon: ReactNode;
  value: NumberOrString;
};

export function LabelLegendTooltip({
  appearances,
  clusterData,
  label,
  value,
  icon,
}: LabelLegendTooltipProps): JSX.Element {
  return (
    <div className="flex flex-col w-fit h-fit border-[1px] border-gray-700 rounded-xl bg-gray-800 -m-4 text-sm text-gray-350">
      <div className="flex flex-row align-baseline p-2 items-center gap-1">
        {icon}
        <p className="mt-[2px]">{label}</p>
      </div>
      {clusterData && clusterData[label]?.length && (
        <div className="p-2 border-t-[1px] border-gray-700">
          <CorrelatedMetadataOnClusterMsg infoElements={clusterData[label]} />
        </div>
      )}
      {appearances && (
        <p className="p-2 border-t-[1px] border-gray-700">
          Appearances: {appearances.get(value)}
        </p>
      )}
    </div>
  );
}

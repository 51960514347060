import clsx from 'clsx';
import { forwardRef, PropsWithChildren } from 'react';
import { XCloseIcon2 } from '../icons';
import { ClassNameProp, ActionResult } from '../../core/types';

export type ChipProps = PropsWithChildren<
  ClassNameProp & {
    onRemove?: () => ActionResult;
    borderClassName?: string;
    showRemoveOnOver?: boolean;
  }
>;

export const Chip = forwardRef<HTMLDivElement, ChipProps>(
  (
    { className, borderClassName, onRemove, children, showRemoveOnOver },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={clsx(
          className,
          'group flex items-center rounded-lg px-2 py-1 border text-gray-200',
          borderClassName
            ? borderClassName
            : 'border-solid border-gray-700 bg-gray-800 hover:bg-gray-700'
        )}
      >
        {children}
        {!!onRemove && (
          <XCloseIcon2
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
            className={clsx(
              'p-[2px] -m-[2px] -mr-1 text-lg ml-1 hover:bg-white/10 cursor-pointer rounded',
              showRemoveOnOver && 'hidden group-hover:block'
            )}
          />
        )}
      </div>
    );
  }
);

Chip.displayName = 'Chip';

import { FC, useCallback, useMemo, useState } from 'react';
import { Project, ProjectStatus } from '@tensorleap/api-client';
import { CloudDlIcon, Plus, UploadIcon } from '../ui/icons';
import { useHistory } from 'react-router-dom';
import {
  NewProjectDialog,
  UpdateProjectDialog,
} from '../actions-dialog/ProjectDialogs';
import { MainNavBar } from '../ui/MainNavBar';
import { ConfirmDialog } from '../ui/atoms/DeleteContentDialog';
import { ModelFields } from '../ui/model-list/types';
import { Table } from '../ui/model-list/table/Table';
import { MenuAction } from '../ui/model-list/table/TableRowActions';
import { HubGalleryContainer } from './HubGallery';
import { useFeatureFlags } from '../core/FeatureFlagsContext';
import { UploadProjectDialog } from '../actions-dialog/UploadProjectDialog';
import { Running } from '../actions-dialog/RunningDialog';
import { useAuth } from '../auth/AuthContext';
import { Title } from '../ui/atoms/Title';
import { CircledIcon } from '../ui/atoms/CircledIcon';
import { ActionButton } from '../ui/molecules/ActionButton';
import { getBasePath } from '../core/api-client';
import { CircularProgress } from '@material-ui/core';
import { Chip } from '../ui/atoms/Chip';
import { buildProjectUrl } from '../url/url-builder';
import { useFetchProjects } from '../core/data-fetching/projects';
import api from '../core/api-client';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';

export const WelcomePage: FC = () => {
  return <WelcomePageView />;
};

export function WelcomePageView(): JSX.Element {
  return (
    <div className="flex flex-col items-stretch w-screen h-screen bg-gray-900">
      <MainNavBar />
      <div className="flex flex-1 w-full max-h-full overflow-hidden">
        <div className="flex-1 grid grid-cols-1 auto 1fr p-8 gap-4 max-h-full overflow-hidden">
          <UserHeader />
          <ProjectList />
          <Running className="max-h-full overflow-hidden" />
        </div>
        <HubGalleryContainer className="w-[30%] p-8 border-l-gray-700 border-l bg-gray-850" />
      </div>
    </div>
  );
}

function UserHeader(): JSX.Element {
  const { user } = useAuth();

  return (
    <div className="flex flex-row items-center">
      <div className="flex flex-col gap-10">
        <div className="flex gap-3 flex-row items-center">
          <CircledIcon
            className="flex !w-16 !h-16"
            textSizeClass="text-4xl"
            text={user?.local.name}
            dropShadow
            borderStyle="border-2"
          />
          <h3 className="font-normal text-4xl leading-tight">
            Welcome to Tensorleap, {user?.local.name || 'Friend'}
          </h3>
        </div>
      </div>
      <span className="flex-1" />
    </div>
  );
}

function formatDate(data: Date | null) {
  return data?.toDateString() || '';
}

const PROJECT_COLUMNS: ModelFields<Project> = [
  {
    accessorKey: 'name',
    label: 'PROJECT NAME',
    format: (projectName, project) => {
      if (project.status === ProjectStatus.Importing) {
        return (
          <div className="flex gap-2 items-center">
            {projectName}
            <Chip className="flex gap-2 text-primary-400">
              <CloudDlIcon className="w-4" />
              Importing
              <CircularProgress size={14} thickness={6} />
            </Chip>
          </div>
        );
      }
      return projectName;
    },
  },
  {
    accessorKey: 'lastAccessed',
    label: 'LAST OPENED',
    table: { align: 'right' },
    format: formatDate,
  },
  {
    accessorKey: 'createdAt',
    label: 'CREATION TIME',
    table: { align: 'right' },
    format: formatDate,
  },
];

function ProjectList(): JSX.Element {
  const { projects, refetch: refetchProjects } = useFetchProjects();
  const [isNewProjectDialogOpen, setIsNewProjectDialogOpen] = useState(false);
  const [isUploadProjectDialogOpen, setIsUploadProjectDialogOpen] = useState(
    false
  );
  const [isDeleteProjectDialogOpen, setIsDeleteProjectDialogOpen] = useState(
    false
  );
  const {
    featureFlags: { useUploadDownloadProject },
  } = useFeatureFlags();
  const [isEditProjectDialogOpen, setIsEditProjectDialogOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const handleDeleteProjectDialogClose = useCallback(() => {
    setIsDeleteProjectDialogOpen(false);
    setSelectedProject(null);
  }, []);

  const handleEditProjectDialogClose = useCallback(() => {
    setIsEditProjectDialogOpen(false);
    setSelectedProject(null);
  }, []);

  const handleConfirmDeleteProject = useCallback(async () => {
    try {
      if (!selectedProject) {
        return;
      }

      try {
        await api.deleteProject({
          projectId: selectedProject.cid,
        });

        await refetchProjects();
      } catch (e) {
        console.error(e);
      }
    } catch (err) {
      console.error(err);
    }
    setIsDeleteProjectDialogOpen(false);
  }, [refetchProjects, selectedProject]);

  const history = useHistory();

  const handleProjectSelected = (project: Project) => {
    history.push(buildProjectUrl(project.cid));
  };

  const projectRowMenu = useMemo<MenuAction<Project>[]>(() => {
    const actions: MenuAction<Project>[] = [
      {
        title: 'Delete Project',
        onSelect: (project) => {
          setSelectedProject(project);
          setIsDeleteProjectDialogOpen(true);
        },
      },
      {
        title: 'Edit Project',
        onSelect: (project) => {
          setSelectedProject(project);
          setIsEditProjectDialogOpen(true);
        },
      },
    ];
    if (useUploadDownloadProject) {
      const downloadProjectAction: MenuAction<Project> = {
        title: 'Download Project',
        onSelect: ({ cid: projectId }) => {
          const downloadUrl = `${getBasePath()}/projects/downloadProject/${projectId}`;
          window.open(downloadUrl, '_blank');
        },
      };
      actions.push(downloadProjectAction);
    }
    return actions;
  }, [useUploadDownloadProject]);

  return (
    <>
      <div className="flex flex-col max-h-full overflow-hidden">
        <div className="flex flex-row justify-between mb-2">
          <Title className="pb-2" bottomBorderClassName="border-b-primary-500">
            RECENT PROJECTS
          </Title>
          <div className="flex gap-4">
            {useUploadDownloadProject && (
              <ActionButton
                onRun={() => setIsUploadProjectDialogOpen(true)}
                icon={<UploadIcon className="h-4 w-4" />}
              >
                UPLOAD PROJECT
              </ActionButton>
            )}
            <ActionButton
              onRun={() => setIsNewProjectDialogOpen(true)}
              icon={<Plus className="h-4 w-4" />}
            >
              NEW PROJECT
            </ActionButton>
          </div>
        </div>

        <Table
          fields={PROJECT_COLUMNS}
          data={projects}
          onRowClick={handleProjectSelected}
          menuActions={projectRowMenu}
          tourId={TOUR_SELECTORS_ENUM.RECENT_PROJECTS_TABLE_ID}
        />

        <ConfirmDialog
          title="Are you sure you want to delete this project?"
          isOpen={isDeleteProjectDialogOpen}
          onClose={handleDeleteProjectDialogClose}
          onConfirm={handleConfirmDeleteProject}
        />
      </div>
      {isNewProjectDialogOpen && (
        <NewProjectDialog
          isOpen={isNewProjectDialogOpen}
          onClose={() => setIsNewProjectDialogOpen(false)}
        />
      )}
      {isUploadProjectDialogOpen && (
        <UploadProjectDialog
          onClose={() => setIsUploadProjectDialogOpen(false)}
        />
      )}
      {isEditProjectDialogOpen && selectedProject && (
        <UpdateProjectDialog
          project={selectedProject}
          isOpen={isEditProjectDialogOpen}
          onClose={() => handleEditProjectDialogClose()}
        />
      )}
    </>
  );
}

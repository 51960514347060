import { DefaultApi, Configuration } from '@tensorleap/api-client';

import { createBrowserHistory } from 'history';
import { URLS_ENUM } from '../url/url-builder';
import keycloak from '../keycloak';

// Create a history object
const history = createBrowserHistory();

const GENERIC_ERROR_MESSAGE = 'Something is not right.';

export class ClientError extends Error {
  constructor(
    msg: string,
    public status: number,
    public params: Record<string, string> = {}
  ) {
    super(msg);
  }
}

export function getBasePath() {
  return `${getApiOrigin()}/api/v2`;
}

export function getApiHost() {
  const { host } = document.location;
  if (host.endsWith('tensorleap.ai')) {
    return `api.${host}`;
  }
  return host;
}

export function getApiOrigin() {
  const apiHost = getApiHost();
  return `${document.location.protocol}//${apiHost}`;
}

async function parseErrorResponse(response: Response) {
  try {
    const { error, ...otherArgs } = await response.json();
    return {
      msg: error || GENERIC_ERROR_MESSAGE,
      status: response.status,
      params: otherArgs,
    };
  } catch {
    return {
      msg: GENERIC_ERROR_MESSAGE,
      status: response.status,
    };
  }
}

const client = new DefaultApi(
  new Configuration({
    basePath: getBasePath(),
    credentials: 'include',
    middleware: [
      {
        pre: async ({ init }) => {
          const token = keycloak.token;
          if (token) {
            init.headers = {
              ...init.headers,
              Authorization: `KBearer ${token}`,
            };
          }
          return;
        },
        post: async ({ response }) => {
          if (response.status === 409) {
            if (history.location.pathname === URLS_ENUM.CONFLICT_USERS) return;
            history.replace(URLS_ENUM.CONFLICT_USERS);
            window.location.reload();
            return;
          }
          if (response.status < 200 || response.status >= 400) {
            const { msg, status, params } = await parseErrorResponse(response);
            throw new ClientError(msg, status, params);
          }
        },
      },
    ],
  })
);

export default client;

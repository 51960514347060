import { useMemo } from 'react';
import { BboxIcon } from '../../../ui/icons';
import { SimpleAutocomplete } from '../../../ui/molecules/SimpleAutocomplete';
import { Setter } from '../../../core/types';

export type ProjectionMetricSelectorProps = {
  projectionMetricsFieldsNames: string[];
  projectionMetric?: string;
  setProjectionMetric: Setter<string | undefined>;
};

export function ProjectionMetricSelector({
  projectionMetricsFieldsNames,
  projectionMetric,
  setProjectionMetric,
}: ProjectionMetricSelectorProps) {
  const options = useMemo(
    () => [
      { value: undefined, label: 'None' },
      ...projectionMetricsFieldsNames.map((metricName) => ({
        value: metricName,
        label: metricName?.startsWith('metrics.')
          ? metricName.split('metrics.')[1]
          : metricName?.startsWith('metadata.')
          ? metricName.split('metadata.')[1]
          : metricName,
      })),
    ],
    [projectionMetricsFieldsNames]
  );

  return (
    <SimpleAutocomplete
      title="Projection metric"
      options={options}
      value={projectionMetric}
      onChange={setProjectionMetric}
      icon={<BboxIcon />}
    />
  );
}

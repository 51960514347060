//! Polyfills - must be first!
import 'core-js/full/array/at';
import 'core-js/full/array/includes';
import 'core-js/full/array/from';
import 'core-js/full/array/flat';
import 'core-js/full/array/flat-map';
import 'core-js/full/object/entries';
import 'core-js/full/object/from-entries';

import React from 'react';
import ReactDOM from 'react-dom';
import { datadogRum } from '@datadog/browser-rum';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { DISABLE_DATADOG_METRICS_KEY } from './core/EnvironmentInfoContext';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: 'login-required' }}
    >
      <App />
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (
  process.env.NODE_ENV === 'production' &&
  localStorage.getItem(DISABLE_DATADOG_METRICS_KEY) === 'false'
) {
  initDatadog();
}

export function initDatadog(): void {
  const locationParts = window.location.host.split('.');
  const domain = locationParts.slice(-2).join('.');
  const subDomain = locationParts.slice(0, -2).join('.') || 'unknown';
  const env = domain === 'tensorleap.ai' ? subDomain : 'local';
  datadogRum.init({
    allowedTracingUrls: [
      /https:\/\/.*\.tensorleap\.ai/,
      new RegExp(`https?://(.*\\.)?${domain}`),
    ],
    applicationId: 'ac1c2696-35f0-4b61-976b-ea17337eb74c',
    clientToken: 'puba3858fbc9b225c7518394ae02b4f3dbf',
    service: 'web-ui',
    env,
    actionNameAttribute: 'data-track-action',
    trackUserInteractions: true,
    sessionReplaySampleRate: 100,
  });
}

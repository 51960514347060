import {
  KeyboardEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { List, ListItem, Popover, Tooltip } from '@material-ui/core';
import { DatasetVersion, TestStatus } from '@tensorleap/api-client';
import clsx from 'clsx';
import { bindPopover } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';

import api from '../../core/api-client';
import { useToggle } from '../../core/useToggle';
import { DoubleCheck, Down, Up, XClose } from '../icons';
import { Button } from '../atoms/Button';
import { Input } from '../atoms/Input';
import { minTwoDigits } from '../../core/formatters/number-formatting';
import { EditIconButton } from '../atoms/EditIconButton';
import { dateFormatter } from '../../core/formatters/date-formatting';
import { testStatusToBg } from '../../assets-management/code-integration/utils/helper-functions';
import { KeyedMutator } from 'swr';

export interface DatasetVersionsPopoverProps {
  datasetVersions: DatasetVersion[];
  currentDatasetVersion: DatasetVersion | undefined;
  handleDatasetVersionIdSelected: (id: string) => void;
  hideNonPassedVersionsByDefault?: boolean;
  allowRenameVersions?: boolean;
  fetchDatasetVersions: KeyedMutator<DatasetVersion[]>;
  hasUnsavedCode?: boolean;
  label?: string;
}
export function DatasetVersionsPopover({
  datasetVersions,
  currentDatasetVersion,
  handleDatasetVersionIdSelected,
  hideNonPassedVersionsByDefault = false,
  allowRenameVersions = true,
  hasUnsavedCode,
  fetchDatasetVersions,
  label = 'VERSION',
}: DatasetVersionsPopoverProps): JSX.Element {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isOnEditMode, setIsOnEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [showOnlyPassedVersions, toggleShowOnlyPassedVersions] = useToggle(
    hideNonPassedVersionsByDefault
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const selectedDatasetVersionIndex = useMemo(() => {
    const index = datasetVersions.findIndex(
      ({ cid }) => cid === currentDatasetVersion?.cid
    );
    return index === -1 ? '' : indexToString(datasetVersions.length - index);
  }, [currentDatasetVersion, datasetVersions]);

  const [modifiedNote, setModifiedNote] = useState<string>();

  useEffect(() => {
    setModifiedNote(currentDatasetVersion?.note);
    setIsOnEditMode(false);
  }, [currentDatasetVersion]);

  const handleSelectClick = useCallback<MouseEventHandler<HTMLElement>>(
    (event) => {
      setIsSelectOpen(true);
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleEditNoteClick = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => {
      setIsOnEditMode(true);
      setAnchorEl(e.currentTarget);
    },
    []
  );

  const handleCloseSelect = useCallback(() => {
    setIsSelectOpen(false);
    setAnchorEl(null);
  }, [setIsSelectOpen]);

  const handleDiscardEditNote = useCallback(() => {
    setIsOnEditMode(false);
    setModifiedNote(currentDatasetVersion?.note || '');
    setAnchorEl(null);
  }, [currentDatasetVersion?.note]);

  const handleNoteChanged = useCallback((e) => {
    setModifiedNote(e.target.value);
  }, []);

  const handleSaveVersionNote = useCallback(async () => {
    setIsSaving(true);
    if (!currentDatasetVersion) {
      console.error('Somehow tried to save without selectedVersion');
      setIsSaving(false);
      return;
    }
    const datasetVersionId = currentDatasetVersion.cid;
    const note = modifiedNote || '';

    setIsOnEditMode(false);
    setAnchorEl(null);
    await api.modifyDatasetVersionNote({ datasetVersionId, note });
    await fetchDatasetVersions();
    setIsSaving(false);
  }, [currentDatasetVersion, modifiedNote, fetchDatasetVersions]);

  const handleKeyDownOnEditNoteInput = useCallback<KeyboardEventHandler>(
    (e) => {
      if (e.key === 'Enter') {
        handleSaveVersionNote();
      } else if (e.key === 'Escape') {
        handleDiscardEditNote();
      }
    },
    [handleDiscardEditNote, handleSaveVersionNote]
  );

  const editIcon = useMemo(
    () => (
      <EditIconButton
        isOnEditMode={isOnEditMode}
        isSaving={isSaving}
        editTooltip="Edit version comment"
        saveTooltip={
          <div className="flex flex-col">
            <p>Save your comment </p>
            <p>
              <span>press</span>
              <span className="bg-primary-925 ml-1">Enter</span>
            </p>
          </div>
        }
        discardTooltip={
          <div className="flex flex-col">
            <p>Discard changes</p>
            <p>
              <span>press</span>
              <span className="bg-primary-925 ml-1">Esc</span>
            </p>
          </div>
        }
        handleDiscard={handleDiscardEditNote}
        handleSave={handleSaveVersionNote}
        handleEditNoteClick={handleEditNoteClick}
      />
    ),
    [
      handleDiscardEditNote,
      handleSaveVersionNote,
      handleEditNoteClick,
      isOnEditMode,
      isSaving,
    ]
  );

  const currentVersionValue = useMemo(
    () =>
      !selectedDatasetVersionIndex
        ? ''
        : hasUnsavedCode
        ? `${selectedDatasetVersionIndex} - Draft`
        : `${selectedDatasetVersionIndex}${
            currentDatasetVersion?.note
              ? ` - ${currentDatasetVersion.note}`
              : ''
          }`,
    [currentDatasetVersion?.note, hasUnsavedCode, selectedDatasetVersionIndex]
  );

  const datasetVersionsOptions = useMemo(
    () =>
      datasetVersions
        .map((dsVertion, index) => ({ ...dsVertion, originalIndex: index }))
        .filter(
          ({ testStatus }) =>
            !showOnlyPassedVersions || testStatus === TestStatus.TestSuccess
        )
        .map((datasetVersion) => (
          <DatasetVersionToListEntity
            key={datasetVersion.cid}
            index={datasetVersions.length - datasetVersion.originalIndex}
            datasetVersion={datasetVersion}
            isSelected={datasetVersion.cid === currentDatasetVersion?.cid}
            handleClose={handleCloseSelect}
            handleDatasetVersionIdSelected={handleDatasetVersionIdSelected}
          />
        )),
    [
      currentDatasetVersion,
      datasetVersions,
      handleCloseSelect,
      handleDatasetVersionIdSelected,
      showOnlyPassedVersions,
    ]
  );

  const popoverState = usePopupState({
    variant: 'popover',
    popupId: null,
  });

  return (
    <>
      {isOnEditMode ? (
        <Input
          onKeyDown={handleKeyDownOnEditNoteInput}
          label="VERSION COMMENT"
          autoFocus
          value={modifiedNote}
          onChange={handleNoteChanged}
          className="min-w-[120px]"
        />
      ) : (
        <Tooltip title={currentDatasetVersion?.note || ''}>
          <Input
            label={label}
            value={currentVersionValue}
            icon={isSelectOpen ? <Up /> : <Down />}
            onClick={handleSelectClick}
            className="min-w-[120px]"
            data-testid="dataset-version-popover"
          />
        </Tooltip>
      )}
      {allowRenameVersions && editIcon}
      <Popover
        {...bindPopover(popoverState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        anchorEl={anchorEl}
        open={isSelectOpen}
        onClose={handleCloseSelect}
        onMouseLeave={popoverState.close}
        PaperProps={{
          style: { overflowY: 'hidden', width: 'fit-content' },
        }}
      >
        <ListItem
          component="li"
          className="flex flex-row justify-between bg-gray-850 text-gray-500"
        >
          <p>VERSIONS HISTORY</p>
          <List className="flex flex-row">
            <Tooltip
              title={
                showOnlyPassedVersions
                  ? 'Show all versions'
                  : 'Show only valid versions'
              }
            >
              <Button
                onClick={toggleShowOnlyPassedVersions}
                variant="text"
                className={clsx(
                  'h-2 w-2 rounded-full',
                  showOnlyPassedVersions && 'bg-secondary-500'
                )}
              >
                <DoubleCheck className="rounded-full text-gray-350" />
              </Button>
            </Tooltip>

            <Button
              onClick={handleCloseSelect}
              variant="text"
              className="h-2 w-2"
            >
              <XClose className="rounded-full text-gray-350" />
            </Button>
          </List>
        </ListItem>

        <div className="max-h-96 min-w-[300px] overflow-y-auto">
          {datasetVersionsOptions.length ? (
            datasetVersionsOptions
          ) : (
            <ListItem className="uppercase justify-center text-gray-400">
              no versions found
            </ListItem>
          )}
        </div>
      </Popover>
    </>
    // </div>
  );
}

interface DatasetVersionToListEntityProps {
  index: number;
  datasetVersion: DatasetVersion;
  isSelected: boolean;
  handleClose: () => void;
  handleDatasetVersionIdSelected: (id: string) => void;
}
function DatasetVersionToListEntity({
  index,
  datasetVersion,
  isSelected,
  handleClose,
  handleDatasetVersionIdSelected,
}: DatasetVersionToListEntityProps): JSX.Element {
  const createdAt = useMemo(
    () => dateFormatter.format(new Date(datasetVersion.createdAt)),
    [datasetVersion]
  );
  const note = datasetVersion?.note || '';

  return (
    <Tooltip title={note}>
      <ListItem
        key={index}
        component="li"
        button
        className={isSelected ? 'bg-gray-700' : 'bg-gray-800'}
        onClick={() => {
          handleDatasetVersionIdSelected(datasetVersion.cid);
          handleClose();
        }}
      >
        <div className="flex justify-start gap-6 text-gray-300">
          <p>{indexToString(index)}</p>

          <div className={clsx('h-2 w-2')}>
            <DoubleCheck
              className={clsx(
                testStatusToBg(datasetVersion.testStatus),
                'rounded-full'
              )}
            />
          </div>

          <span className="w-96 whitespace-nowrap overflow-x-hidden text-ellipsis">
            {note}
          </span>

          <h5 className="w-40 whitespace-nowrap overflow-x-hidden">
            {createdAt}
          </h5>
        </div>
      </ListItem>
    </Tooltip>
  );
}

function indexToString(index: number): string {
  return minTwoDigits.format(index);
}

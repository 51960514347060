import {
  VisualizedItem,
  GradsAnalysis,
  BBoxImageData,
  GraphData,
  HorizontalBarData,
  ImageHeatmapData,
  MaskImageData,
  MaskTextData,
  TextData,
  VisData,
  ImageData,
  CompositeVizData,
  CompositeVizItem,
  GradsItem,
  SampleIdentity,
} from '@tensorleap/api-client';
import { first } from 'lodash';
import { MetadataTooltipProps } from './VisData';
import { SampleAnalysisVizPayloadItem } from '../../../core/data-fetching/fullVisualizations';

export type MetadataMap = Record<string, string>;

export type VisAssetWithIdentity = {
  data: VisualizedItem | MetadataMap;
  sampleIdentity: SampleIdentity;
};

export enum VisPayloadType {
  Metadata = 'Metadata',
  Analysis = 'Analysis',
  Visualization = 'Visualization',
}

const METADATA_PREFIX = `#${VisPayloadType.Metadata}#`;
const VISUALIZATION_PREFIX = `#${VisPayloadType.Visualization}#`;

export function markAsMetadataPreview(previewBy: string): string {
  return `${METADATA_PREFIX}${previewBy}`;
}

export function markAsVisualizationPreview(previewBy: string): string {
  return `${VISUALIZATION_PREFIX}${previewBy}`;
}

export function isMetaDataPreview(
  previewBy?: string
): previewBy is VisPayloadType.Metadata {
  return !!previewBy?.startsWith(METADATA_PREFIX);
}

export function isVisualizationPreview(
  previewBy?: string
): previewBy is VisPayloadType.Visualization {
  return !!previewBy?.startsWith(VISUALIZATION_PREFIX);
}

export function getMetaDataPreviewValue(previewBy?: string): string {
  return previewBy?.split(METADATA_PREFIX)[1] || '';
}

export function getVisualizationPreviewValue(previewBy?: string): string {
  return previewBy?.split(VISUALIZATION_PREFIX)[1] || '';
}

export interface VisPayloadElements {
  [VisPayloadType.Metadata]: Record<string, VisAssetWithIdentity[]>;
  [VisPayloadType.Analysis]: Record<
    string,
    Record<string, VisAssetWithIdentity[]>
  >;
  [VisPayloadType.Visualization]: Record<
    string,
    Record<string, VisAssetWithIdentity[]>
  >;
}

function processAnalysisItem(
  visItem: VisualizedItem,
  sessionRunId: string,
  analysisMap: Record<string, Record<string, VisAssetWithIdentity[]>>,
  sampleIdentity: SampleIdentity
): void {
  const gradItem = first((visItem.data as GradsAnalysis).data);
  if (!gradItem) return;
  const groupId =
    gradItem.node_name || gradItem.visualizer_name || gradItem.connection_name;
  if (!analysisMap[groupId]) {
    analysisMap[groupId] = {};
  }
  if (!analysisMap[groupId][sessionRunId]) {
    analysisMap[groupId][sessionRunId] = [];
  }
  analysisMap[groupId][sessionRunId].push({ data: visItem, sampleIdentity });
}

function processVisualizationItem(
  visItem: VisualizedItem,
  sessionRunId: string,
  visualizationMap: Record<string, Record<string, VisAssetWithIdentity[]>>,
  sampleIdentity: SampleIdentity
): void {
  const groupId = visItem.connection_name || visItem.visualizer_name;
  if (!visualizationMap[groupId]) {
    visualizationMap[groupId] = {};
  }
  if (!visualizationMap[groupId][sessionRunId]) {
    visualizationMap[groupId][sessionRunId] = [];
  }
  visualizationMap[groupId][sessionRunId].push({
    data: visItem,
    sampleIdentity,
  });
}

export interface ExtractVisDataProps {
  sessionVisualizationsMap: Record<string, SampleAnalysisVizPayloadItem[]>;
}

export function extractVisData({
  sessionVisualizationsMap,
}: ExtractVisDataProps): VisPayloadElements {
  const initialAccumulator = {
    metadataMap: {} as Record<string, VisAssetWithIdentity[]>,
    analysisMap: {} as Record<string, Record<string, VisAssetWithIdentity[]>>,
    visualizationMap: {} as Record<
      string,
      Record<string, VisAssetWithIdentity[]>
    >,
  };

  const { metadataMap, analysisMap, visualizationMap } = Object.entries(
    sessionVisualizationsMap
  ).reduce((accumulator, [sessionRunId, payloadItems]) => {
    payloadItems.forEach((payloadItem) => {
      const {
        payload: { visualized_items, metadata_map },
        slimVisualization: { sampleIdentity },
      } = payloadItem;

      if (!accumulator.metadataMap[sessionRunId]) {
        accumulator.metadataMap[sessionRunId] = [];
      }
      accumulator.metadataMap[sessionRunId].push({
        data: metadata_map,
        sampleIdentity,
      });

      visualized_items.forEach((visItem) => {
        if (visItem?.data?.type === 'grad_analysis') {
          processAnalysisItem(
            visItem,
            sessionRunId,
            accumulator.analysisMap,
            sampleIdentity
          );
        } else {
          processVisualizationItem(
            visItem,
            sessionRunId,
            accumulator.visualizationMap,
            sampleIdentity
          );
        }
      });
    });

    return accumulator;
  }, initialAccumulator);

  return {
    [VisPayloadType.Metadata]: metadataMap,
    [VisPayloadType.Visualization]: visualizationMap,
    [VisPayloadType.Analysis]: analysisMap,
  };
}

export type VisDataTypes =
  | VisData
  | GradsAnalysis
  | CompositeVizData
  | MetadataTooltipProps;

export function isImageData(data?: VisDataTypes): data is ImageData {
  return data?.type === 'image';
}

export function isImageHeatmapData(
  data?: VisDataTypes
): data is ImageHeatmapData {
  return data?.type === 'image_heatmap';
}

export function isImageWithBBoxData(
  data?: VisDataTypes
): data is BBoxImageData {
  return data?.type === 'bbox_image';
}

export function isImageWithSegmentaionData(
  data?: VisDataTypes
): data is MaskImageData {
  return data?.type === 'mask_image';
}

export function isTextWithSegmentaionData(
  data?: VisDataTypes
): data is MaskTextData {
  return data?.type === 'mask_text';
}

export function isHorizontalBarData(
  data?: VisDataTypes
): data is HorizontalBarData {
  return data?.type === 'hbar';
}

export function isTextData(data?: VisDataTypes): data is TextData {
  return data?.type === 'text';
}

export function isGraphData(data?: VisDataTypes): data is GraphData {
  return data?.type === 'graph';
}

export function isGradAnalysisData(data?: VisDataTypes): data is GradsAnalysis {
  return data?.type === 'grad_analysis';
}

export function isCompositeVisData(
  data?: VisDataTypes
): data is CompositeVizData {
  return data?.type === 'composite_visualization';
}

export function isGradsItems(
  data?: (GradsItem | CompositeVizItem)[]
): data is GradsItem[] {
  const firstItem = first(data);
  return !!firstItem && 'grads' in firstItem;
}

export function isCompositeVizItems(
  data?: (GradsItem | CompositeVizItem)[]
): data is CompositeVizItem[] {
  const firstItem = first(data);
  return !!firstItem && 'data' in firstItem;
}

export function isGradsItem(
  data?: GradsItem | CompositeVizItem
): data is GradsItem {
  return !!data && 'grads' in data;
}

export function isCompositeVizItem(
  data?: GradsItem | CompositeVizItem
): data is CompositeVizItem {
  return !!data && 'data' in data;
}

import { PropsWithChildren } from 'react';
import {
  SampleAnalysisPreviewContainer,
  SampleAnalysisPreviewContainerProps,
} from '../../SampleAnalysisView/SampleAnalysisPreviewWrapper';
import {
  PopulationExplorationPreviewWrapper,
  PopulationExplorationPreviewWrapperProps,
} from '../../ScatterAnalyzerView/PopulationExplorationPreviewWrapper';

export type PreviewContainerWrapperProps = PropsWithChildren<
  SampleAnalysisPreviewContainerProps | PopulationExplorationPreviewWrapperProps
>;

export function PreviewContainerWrapper(
  props: PreviewContainerWrapperProps
): JSX.Element {
  if (props.type === 'sampleAnalysis') {
    const saProps = props as SampleAnalysisPreviewContainerProps;
    return <SampleAnalysisPreviewContainer {...saProps} />;
  }

  const peProps = props as PopulationExplorationPreviewWrapperProps;
  return <PopulationExplorationPreviewWrapper {...peProps} />;
}

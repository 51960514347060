import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useMemo, useState } from 'react';
import api, { getBasePath } from '../core/api-client';
import { CopyButton } from './atoms/CopyButton';

export function GenerateCliLogin(): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hasTokenGenerated, setHasTokenGenerated] = useState<boolean>(false);
  const [apiKey, setApiKey] = useState<string>();

  const suggestGenerateTokenDialogContent = useMemo(() => {
    return (
      <div>
        {
          <div>
            <p>
              This operation will disable your old CLI token if exists, are you
              sure you want to continue?
            </p>

            <div className="flex flex-row justify-between items-center my-1 mx-0">
              <Button
                onClick={async () => {
                  try {
                    setHasTokenGenerated(true);
                    const { apiKey } = await api.keyGen();
                    setApiKey(apiKey);
                  } catch (err) {
                    setHasTokenGenerated(false);
                    console.error(err);
                  }
                }}
                className="bg-error-500"
              >
                Yes, disable my old token and generate a new one
              </Button>

              <Button
                onClick={() => {
                  setIsOpen(false);
                }}
                className="bg-gray-800"
              >
                Cancel
              </Button>
            </div>
          </div>
        }
      </div>
    );
  }, []);

  const copyTokenDialogContent = useMemo(() => {
    const apiUrl = getBasePath();
    const loginCommand = `leap auth login ${apiKey} ${apiUrl}`;
    return apiKey ? (
      <div className="flex flex-row justify-center items-center">
        <div>{loginCommand}</div>
        <CopyButton textToCopy={loginCommand} />
      </div>
    ) : (
      <p>loading...</p>
    );
  }, [apiKey]);

  return (
    <div>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
        className="bg-gray-800"
      >
        Generate CLI token
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <DialogTitle>Generate CLI Token</DialogTitle>
        <DialogContent>
          <div className="flex flex-1 flex-row justify-center items-center">
            {hasTokenGenerated
              ? copyTokenDialogContent
              : suggestGenerateTokenDialogContent}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

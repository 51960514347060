import { Dashlet } from '@tensorleap/api-client';
import { useCallback, useMemo } from 'react';
import { useModelFilter } from '../ui/molecules/useModelFilter';
import { useDashboardContext } from './DashboardContext';

export function useDashlet(id: string) {
  const {
    selected,
    removeDashlet,
    duplicateDashlet,
    updateDashlet: _updateDashlet,
    dashletInEditMode,
    setDashletInEditMode,
  } = useDashboardContext();
  const { selected: _selectedModels } = useModelFilter();

  const selectedSessionRuns = useMemo(
    () => _selectedModels.filter(({ visible }) => visible),
    [_selectedModels]
  );

  const toggleEditMode = useCallback(
    () => setDashletInEditMode(dashletInEditMode === id ? '' : id),
    [id, dashletInEditMode, setDashletInEditMode]
  );

  const removeFromDashboard = useCallback(() => {
    return selected && removeDashlet(selected, id);
  }, [selected, removeDashlet, id]);

  const duplicateInDashboard = useCallback(() => {
    return selected && duplicateDashlet(selected, id);
  }, [selected, duplicateDashlet, id]);

  const updateDashlet = useCallback(
    (dashlet: Dashlet) => {
      return selected && _updateDashlet(selected, dashlet);
    },
    [selected, _updateDashlet]
  );

  return useMemo(
    () => ({
      toggleEditMode,
      removeFromDashboard,
      duplicateInDashboard,
      updateDashlet,
      editMode: dashletInEditMode === id,
      selectedSessionRuns,
    }),
    [
      updateDashlet,
      toggleEditMode,
      removeFromDashboard,
      duplicateInDashboard,
      id,
      selectedSessionRuns,
      dashletInEditMode,
    ]
  );
}

import clsx from 'clsx';
import { Tooltip } from '../mui';
import { Select, SelectProps } from '../atoms/Select';
import { TooltipProps } from '@material-ui/core';
import { stopPropagation } from '../../core/stopPropagation';

export type SimpleAutocompleteProps<Option> = SelectProps<Option> & {
  narrow?: boolean;
  title: TooltipProps['title'];
  tourId?: string;
};
export function SimpleAutocomplete<Option>({
  narrow,
  title,
  tourId,
  ...selectProps
}: SimpleAutocompleteProps<Option>) {
  return (
    <Tooltip title={title}>
      <div
        onMouseDown={stopPropagation}
        className={clsx(narrow && 'max-w-[6em]')}
        id={tourId}
      >
        <Select clean small {...selectProps} />
      </div>
    </Tooltip>
  );
}

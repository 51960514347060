import { useCallback, useMemo } from 'react';
import { MoreUp } from '../../../ui/icons';
import { useNetworkMapContext } from '../../../core/NetworkMapContext';
import { useDatasets } from '../../../core/DatasetsContext';
import {
  NetworkWizardData,
  GraphErrorKind,
  NetworkWizardErrorSeverity,
  NetworkWizardCategory,
} from '../types';
import { GraphErrorMsg } from '../errors';
import { NetworkTabsEnum } from '../../NetworkDrawer';

const TITLE = 'NEW CODE INTEGRATION';

export function useCodeIntegrationErrorData(): NetworkWizardData[] {
  const { updateDataset, setOpenNetworkTab } = useNetworkMapContext();
  const { dataset, datasets, switchDatasetVersion } = useDatasets();

  const quickFix = useMemo(() => {
    const latestVersion = dataset?.latestValidVersion;
    if (!latestVersion) {
      return;
    }

    const currentDataset = datasets.find(
      (dataset) => dataset.cid === latestVersion.datasetId
    );

    if (!currentDataset) {
      return;
    }

    const onSelect = () => {
      updateDataset(currentDataset, latestVersion);
      setOpenNetworkTab(NetworkTabsEnum.CodeIntegration);
      switchDatasetVersion(latestVersion);
    };

    return [
      {
        title: 'Update',
        onSelect,
        disableQuickFix: false,
        icon: <MoreUp className="w-5" />,
      },
    ];
  }, [
    dataset,
    datasets,
    setOpenNetworkTab,
    switchDatasetVersion,
    updateDataset,
  ]);

  const calculateKey = useCallback(
    () => GraphErrorMsg.UpdateCodeIntegration + TITLE,
    []
  );

  return useMemo(
    () => [
      {
        errorType: GraphErrorKind.codeIntegration,
        category: NetworkWizardCategory.CODE,
        title: TITLE,
        calculateKey,
        message: GraphErrorMsg.UpdateCodeIntegration,
        showNodeFooter: true,
        quickFixes: quickFix,
        headerIcon: <MoreUp />,
        errorSeverity: NetworkWizardErrorSeverity.WARNING,
        key: calculateKey(),
      },
    ],
    [calculateKey, quickFix]
  );
}

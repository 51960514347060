import { ReactNode, useEffect, useRef, useState } from 'react';
import { Setter } from '../../../core/types';
import { useDebounce } from '../../../core/useDebounce';
type StringOrNumber = string | number;
export type OptionDefaultObj<Value extends StringOrNumber = StringOrNumber> = {
  value: Value;
  label?: ReactNode;
};
export type OptionValue = StringOrNumber;
export type AsyncOption<Option> =
  | Option[]
  | ((query: string) => Promise<Option[]>);

export const enumToOptions = <Value extends OptionValue>(
  v: Record<string, Value>
): OptionDefaultObj<Value>[] => {
  return Object.entries(v).map(([label, value]) => ({ label, value }));
};
export type OptionType = OptionDefaultObj | OptionValue;

export const validateOption = (x: unknown, key = 'value') => {
  if (x && !Object.getOwnPropertyDescriptor(x as Record<string, unknown>, key))
    throw new Error(
      `[Select::Option] invalid option. Use toLabel map functions or send optionID or make sure your option is implemented OptionType (string | number | {value: string | number, label?: ReactNode})`
    );
};

export const buidDefaultLabelMap = (valueKey = 'value') => (x: unknown) => {
  const title =
    typeof x === 'string' || !x || typeof x === 'number'
      ? x
      : (x as OptionDefaultObj).label
      ? (x as OptionDefaultObj).label
      : (x as OptionDefaultObj)[valueKey as keyof OptionDefaultObj];
  !title && validateOption(x, valueKey);
  return title as string;
};
export const defaultLabelMap = buidDefaultLabelMap();

export const mapValue = (x: unknown, key: string): OptionValue => {
  const value =
    typeof x === 'string' || !x || typeof x === 'number'
      ? x
      : (x as Record<string, OptionValue>)[key];
  !value && validateOption(x);
  return value as OptionValue;
};

export type UseAsyncOptions<Option> = {
  loadedOptions: Option[];
  loading: boolean;
  query: string;
  setQuery: Setter<string>;
};

export function useAsyncOptions<Option>(
  options: AsyncOption<Option>,
  defaultQuery: string = ''
): UseAsyncOptions<Option> {
  const [query, setQuery] = useState(defaultQuery);
  const queryRef = useRef(query);
  queryRef.current = query;
  const [loading, setLoading] = useState(false);
  const [loadedOptions, setLoadedOptions] = useState<Option[]>([]);

  const fetchData = useDebounce(async () => {
    const usedQuery = queryRef.current;
    if (typeof options !== 'function' || typeof query !== 'string') return;
    const newOptions = await options(usedQuery);
    if (usedQuery !== queryRef.current) {
      return;
    }
    newOptions.length && setLoadedOptions(newOptions);
    setLoading(false);
  }, 500);

  useEffect(() => {
    if (typeof options !== 'function') {
      return;
    }
    setLoading(true);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, fetchData]);

  return { loadedOptions, loading, query, setQuery };
}

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { uniq } from 'lodash';
import { Setter } from '../../../core/types';
import { useMergedObject } from '../../../core/useMergedObject';
import {
  ScatterSettingValues,
  ScatterSettingOptions,
  ScatterSetting,
  settingValuesWithDefault,
} from './hooks';
import {
  DashboardFiltersParams,
  DashboardFiltersResponse,
  useDashboardFilters,
} from '../useDashletFields';

export function useScatterOptions(
  scatterSettingOptions: ScatterSettingOptions,
  scatterValues: ScatterSettingValues,
  setScatterValues: Setter<ScatterSettingValues>
): ScatterSetting {
  return useMemo(() => {
    const sizeOrShape = {
      options: scatterSettingOptions.sizeOrShape,
      value: scatterValues.sizeOrShape,
      setOption: (option?: string) =>
        setScatterValues({ ...scatterValues, sizeOrShape: option }),
    };
    const dotColor = {
      options: scatterSettingOptions.dotColor,
      value: scatterValues.dotColor,
      setOption: (option?: string) =>
        setScatterValues({ ...scatterValues, dotColor: option }),
    };
    const previewBy = {
      options: scatterSettingOptions.previewBy,
      value: scatterValues.previewBy,
      setOption: (option?: string | null) =>
        setScatterValues({ ...scatterValues, previewBy: option }),
    };

    return {
      sizeOrShape,
      dotColor,
      previewBy,
    };
  }, [scatterSettingOptions, scatterValues, setScatterValues]);
}

export interface DashletScatterContextValue {
  projectId: string;

  register: (key: string, options: ScatterSettingOptions) => void;
  unregister: (key: string) => void;
  settingsValues: ScatterSettingValues;
  settings: ScatterSetting;

  projectionMetric?: string;
  setProjectionMetric: Setter<string | undefined>;
  projectionMetricsFieldsNames: string[];

  filters: DashboardFiltersResponse;
}
export const contextDefaults: DashletScatterContextValue = {
  projectId: '',
  register: () => undefined,
  unregister: () => undefined,
  settingsValues: {} as ScatterSettingValues,
  settings: {} as ScatterSetting,
  projectionMetric: undefined,
  setProjectionMetric: () => undefined,
  projectionMetricsFieldsNames: [],
  filters: {} as DashboardFiltersResponse,
};
const DashletScatterContext = createContext<DashletScatterContextValue>(
  contextDefaults
);

type DashletScatterContextProviderProps = PropsWithChildren<{
  projectId: string;
  filterProps: Omit<
    DashboardFiltersParams,
    'projectId' | 'useRegisteredFilters'
  >;
}>;

export function DashletScatterContextProvider({
  children,
  projectId,
  filterProps,
}: DashletScatterContextProviderProps): JSX.Element {
  const [scatterOptionsByKey, setScatterOptionByKey] = useState<
    Record<string, ScatterSettingOptions>
  >({});

  const scatterOptions = useMemo(() => {
    const allOptions = Object.values(scatterOptionsByKey);
    const options = {
      sizeOrShape: uniq(allOptions.flatMap((o) => o.sizeOrShape)),
      dotColor: uniq(allOptions.flatMap((o) => o.dotColor)),
      previewBy: uniq(allOptions.flatMap((o) => o.previewBy)),
    };

    return options;
  }, [scatterOptionsByKey]);

  const [scatterValues, setScatterValues] = useState<ScatterSettingValues>({
    sizeOrShape: 'metrics.loss',
    dotColor: 'metrics.loss',
    previewBy: null,
  });

  const valuesWithDefault = useMemo(
    () => settingValuesWithDefault(scatterValues, scatterOptions),
    [scatterValues, scatterOptions]
  );

  const settings = useScatterOptions(
    scatterOptions,
    valuesWithDefault,
    setScatterValues
  );

  const filters = useDashboardFilters({
    projectId,
    ...filterProps,
    useRegisteredFilters: true,
  });

  const [projectionMetric, setProjectionMetric] = useState<string>();

  const projectionMetricsFieldsNames = useMemo(
    () =>
      filters.filterFieldsMeta
        .filter(
          ({ field }) =>
            field.startsWith('metrics.') || field.startsWith('metadata.')
        )
        .map(({ field }) => field),
    [filters.filterFieldsMeta]
  );

  const register = useCallback(
    (key: string, options: ScatterSettingOptions) => {
      setScatterOptionByKey((prev) => ({ ...prev, [key]: options }));
    },
    []
  );

  const unregister = useCallback((key: string) => {
    setScatterOptionByKey((prev) => {
      const { [key]: _, ...rest } = prev;
      return rest;
    });
  }, []);

  const value: DashletScatterContextValue = useMergedObject({
    projectId,
    register,
    unregister,
    settings,
    settingsValues: valuesWithDefault,
    projectionMetric,
    setProjectionMetric,
    projectionMetricsFieldsNames,
    filters,
  });
  return (
    <DashletScatterContext.Provider value={value}>
      {children}
    </DashletScatterContext.Provider>
  );
}

export function useDashletScatterContext(): DashletScatterContextValue {
  return useContext(DashletScatterContext);
}

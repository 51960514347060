import { ReactNode, useCallback, useMemo } from 'react';
import clsx from 'clsx';

import {
  isBTWVisualizationFilter,
  isEQVisualizationFilter,
  isINVisualizationFilter,
  isNotBTWVisualizationFilter,
  isNEVisualizationFilter,
  isFilterLoading,
  isInsightFilter,
  isClusterVisualizationFilter,
} from '../core/filters';
import {
  VisualizationFilter,
  isFetchSimilarDisplayData,
  isInsightDisplayData,
} from '../core/types/filters';
import {
  GridMagnet,
  LightBulbOnIcon,
  NonVisible,
  PushpinOffIcon,
  PushpinOnIcon,
  Visible,
  XClose,
} from '../ui/icons';
import { isEqual } from 'lodash';
import { FilterFormPopup } from './FilterFormPopup';
import { FilterFieldMeta } from './helpers';
import { useOpenState } from '../ui/atoms/utils/useOpenState';
import { ClassNameProp } from '../core/types';
import { LinearProgress, Tooltip } from '@material-ui/core';
import { InsightCard } from '../insights/InsightCard';
import { FetchSimilarPopup } from './FetchSimilarPopup';
import { FetchSimilarFilterDisplayData } from '@tensorleap/api-client';
import { useModelFilter } from '../ui/molecules/useModelFilter';
import { useDashboardContext } from '../dashboard/DashboardContext';
import { truncateLongtail } from '../core/formatters/string-formatting';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';

export function getFilterText(filter: VisualizationFilter): string {
  const { displayData } = filter;
  if (displayData && isInsightDisplayData(displayData)) {
    return displayData.insightData.type.replaceAll('_', ' ');
  } else if (displayData && isFetchSimilarDisplayData(displayData)) {
    return calcFetchSimilarFilterChipText(displayData);
  } else if (isClusterVisualizationFilter(filter)) {
    return `Cluster: ${truncateLongtail({
      value: filter.value.url,
      endSubsetLength: 20,
    })}`;
  } else if (isEQVisualizationFilter(filter)) {
    return `${filter.field} = ${filter.value}`;
  } else if (isNEVisualizationFilter(filter)) {
    return `${filter.field} ≠ ${filter.value}`;
  } else if (isBTWVisualizationFilter(filter)) {
    const {
      field,
      value: { gte, lt },
    } = filter;
    let result = field;
    if (gte !== undefined) {
      result = `${gte} ≤ ${result}`;
    }
    if (lt !== undefined) {
      result = `${result} < ${lt}`;
    }
    return result;
  } else if (isNotBTWVisualizationFilter(filter)) {
    const {
      field,
      value: { gte, lt },
    } = filter;
    const result = [];
    if (gte !== undefined) {
      result.push(`${field} < ${gte}`);
    }
    if (lt !== undefined) {
      result.push(`${field} > ${lt}`);
    }
    return result.join(' OR ');
  } else if (isINVisualizationFilter(filter)) {
    const listValue = filter.value;
    const mappedValue =
      listValue.length > 3
        ? `${listValue.slice(0, 2).join(', ')}, +${listValue.length - 2}`
        : listValue.join(', ');
    return `${filter.field} is in ${mappedValue}`;
  } else {
    return '';
  }
}

interface FilterItemProps {
  filter: VisualizationFilter;
  filterFieldsMeta: FilterFieldMeta[];
  readOnly?: boolean;
  onFiltersChange: (_: VisualizationFilter[]) => void;
  onChange: (
    oldFilter: VisualizationFilter,
    newFilter: VisualizationFilter
  ) => void;
  onRemove: (_: VisualizationFilter) => void;
  allowDisable: boolean;
  allowPin: boolean;
}

type FilterChipProps = ClassNameProp & {
  children: ReactNode;
  loading?: boolean;
  tourId?: string;
};

export function FilterChip({
  children,
  className,
  loading,
  tourId,
}: FilterChipProps) {
  return (
    <li
      className={clsx(
        'h-6 w-max px-2 flex gap-2 justify-center bg-gray-800 rounded drop-shadow items-center text-sm',
        loading && 'relative overflow-hidden',
        className
      )}
      id={tourId}
    >
      {children}
      {loading && (
        <div className="h-[2px] absolute bottom-0 inset-x-0">
          <LinearProgress />
        </div>
      )}
    </li>
  );
}

function calcFetchSimilarFilterChipText(
  displayData: FetchSimilarFilterDisplayData
): string {
  const { sampleIds } = displayData;
  if (sampleIds.length === 1) {
    const [sample] = sampleIds;
    return `Similar To Sample #${sample.index}, ${sample.state} `;
  }
  return `Similar To ${sampleIds.length} Samples`;
}

function FilterItem({
  filter,
  onChange,
  onRemove,
  filterFieldsMeta,
  readOnly,
  allowDisable,
  allowPin,
}: FilterItemProps) {
  const { selected: selectedSessionRuns } = useModelFilter();

  const { clearDisplayedInsightsHistory } = useDashboardContext();

  const { displayData } = filter;

  const modelUniqueKey = useMemo(() => {
    if (!displayData) {
      return -1;
    }
    const selectedSessionRun = selectedSessionRuns.find(
      (sr) => sr.id === displayData.sessionRun.id
    );

    if (!selectedSessionRun) {
      return -1;
    }

    return selectedSessionRun.modelUniqueKey;
  }, [displayData, selectedSessionRuns]);

  const { isOpen, close, open } = useOpenState(false);

  const filterChipElement = useMemo(() => {
    return (
      <div
        className={clsx(
          'flex flex-row gap-1 whitespace-nowrap',
          filter.disable && 'line-through opacity-60'
        )}
        onClick={() => !readOnly && open()}
      >
        {displayData && isInsightDisplayData(displayData) ? (
          <LightBulbOnIcon
            className={clsx(
              displayData.insightData.type.replaceAll('_', ' '),
              'h-5'
            )}
          />
        ) : displayData && isFetchSimilarDisplayData(displayData) ? (
          <GridMagnet className="h-5 text-warning-400" />
        ) : (
          <></>
        )}

        <span className="capitalize">{getFilterText(filter)}</span>
      </div>
    );
  }, [displayData, filter, open, readOnly]);

  const content = useMemo(
    () =>
      displayData && isInsightDisplayData(displayData) ? (
        <InsightCard
          insightData={displayData.insightData}
          selectedSessionRun={{
            name: displayData.sessionRun.name,
            id: displayData.sessionRun.id,
            sessionId: displayData.sessionRun.sessionId,
            visible: true,
            modelUniqueKey,
          }}
        />
      ) : displayData && isFetchSimilarDisplayData(displayData) ? (
        <FetchSimilarPopup {...displayData} />
      ) : undefined,
    [displayData, modelUniqueKey]
  );

  const removeFilter = useCallback(() => {
    onRemove(filter);

    if (isInsightFilter(filter)) {
      clearDisplayedInsightsHistory();
    }
  }, [clearDisplayedInsightsHistory, filter, onRemove]);

  const handleFilterChange = useCallback(
    (newFilter: VisualizationFilter) => {
      onChange(filter, newFilter);
    },
    [filter, onChange]
  );

  const toggleDisable = useCallback(() => {
    onChange(filter, { ...filter, disable: !filter.disable });
  }, [filter, onChange]);

  const togglePin = useCallback(() => {
    onChange(filter, { ...filter, pin: !filter.pin });
  }, [filter, onChange]);

  return (
    <FilterChip
      loading={isFilterLoading(filter)}
      className={clsx('group relative')}
      tourId={
        isFetchSimilarDisplayData(displayData)
          ? TOUR_SELECTORS_ENUM.FETCH_SIMILAR_FILTER_ID
          : undefined
      }
    >
      {filter.pin && (
        <FilterIcon
          className="bg-primary-900 rounded-full border border-white/20"
          title="Unpin Filter"
          onClick={togglePin}
          Icon={PushpinOnIcon}
        />
      )}
      <FilterFormPopup
        onApply={handleFilterChange}
        onClose={close}
        defaultFilter={filter}
        fields={filterFieldsMeta}
        open={isOpen}
        content={content}
      >
        {filterChipElement}
      </FilterFormPopup>
      <div className="px-1 gap-1 bg-gray-800/80 absolute right-0 hidden group-hover:flex">
        {allowPin && !filter.pin && (
          <FilterIcon
            title="Pin Filter"
            onClick={togglePin}
            Icon={PushpinOffIcon}
          />
        )}

        {allowDisable &&
          !filter.pin &&
          (filter.disable ? (
            <FilterIcon
              title="Enable Filter"
              onClick={toggleDisable}
              Icon={Visible}
            />
          ) : (
            <FilterIcon
              title="Disable Filter"
              onClick={toggleDisable}
              Icon={NonVisible}
            />
          ))}

        {!readOnly && (
          <FilterIcon
            title="Remove Filter"
            onClick={removeFilter}
            Icon={XClose}
          />
        )}
      </div>
    </FilterChip>
  );
}

type FilterIconProps = {
  onClick: () => void;
  Icon: React.ComponentType<ClassNameProp>;
  title: string;
  className?: string;
};

function FilterIcon({ onClick, Icon, title, className }: FilterIconProps) {
  return (
    <Tooltip title={title}>
      <div onClick={onClick} className={className}>
        <Icon className="h-5 w-5 m-0 p-0 cursor-pointer" />
      </div>
    </Tooltip>
  );
}

export interface FilterElementsProps {
  filters: VisualizationFilter[];
  onFiltersChange: (_: VisualizationFilter[]) => void;
  listClassName?: string;
  buttonClassName?: string;
  filterFieldsMeta: FilterFieldMeta[];
  extraContent?: ReactNode;
  readOnly?: boolean;
  allowDisable: boolean;
  allowPin: boolean;
}
export function FilterElements({
  filters,
  onFiltersChange,
  filterFieldsMeta,
  listClassName,
  extraContent,
  readOnly,
  allowDisable,
  allowPin,
}: FilterElementsProps) {
  const removeFilter = useCallback(
    (removedFilter: VisualizationFilter) => {
      onFiltersChange(filters.filter((filter) => removedFilter !== filter));
    },
    [onFiltersChange, filters]
  );

  const handleFilterChange = useCallback(
    (oldFilter: VisualizationFilter, newFilter: VisualizationFilter) => {
      onFiltersChange(
        filters.map((filter) =>
          isEqual(filter, oldFilter) ? newFilter : filter
        )
      );
    },
    [filters, onFiltersChange]
  );

  return (
    <>
      <ul
        className={clsx(
          'flex gap-2 items-center overflow-x-scroll scrollbar-hide',
          listClassName
        )}
      >
        {filters.map((filter, index) => (
          <FilterItem
            key={index}
            filter={filter}
            onRemove={removeFilter}
            onChange={handleFilterChange}
            filterFieldsMeta={filterFieldsMeta}
            readOnly={readOnly}
            onFiltersChange={onFiltersChange}
            allowDisable={allowDisable}
            allowPin={allowPin}
          />
        ))}
        {extraContent && <li>{extraContent}</li>}
      </ul>
    </>
  );
}

import React, { useCallback, useMemo, useState } from 'react';
import { Clusters, Heatmap } from '@tensorleap/api-client';
import { scaleLinear } from 'd3';
import { Range, Slider } from './atoms/Slider';
import { ColorIcon } from './icons';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';

export interface TextHeatMapProps {
  text: string | string[];
  clusters?: Clusters;
  heatmap?: Heatmap;
  className?: string;
}

export const TextHeatMap = React.memo<TextHeatMapProps>(
  ({ text, heatmap, clusters, className }) => {
    const textArray = useMemo(
      () => (Array.isArray(text) ? text : text.split(' ')),
      [text]
    );
    const [heatmapFilterRange, setHeatmapFilterRange] = useState<Range>([0, 1]);
    const setHeapMapRange = useCallback(
      (_: React.ChangeEvent<object>, newValue: number | number[]) => {
        setHeatmapFilterRange(newValue as Range);
      },
      []
    );

    const scaleHeatmapFilterRange = useMemo(
      () => scaleLinear().domain(heatmapFilterRange).range([0, 1]),
      [heatmapFilterRange]
    );

    const getWordColor = useCallback(
      (index: number) =>
        clusters ? getClusterColor(clusters.data[index]) : 'white',
      [clusters]
    );

    const getBackgroundColor = useCallback(
      (index: number) =>
        heatmap
          ? `rgba(255, 0, 0, ${scaleHeatmapFilterRange(heatmap.body[index])})`
          : 'transparent',
      [heatmap, scaleHeatmapFilterRange]
    );

    return (
      <div
        className={clsx(
          'w-full h-full py-2 px-3 flex flex-col bg-gray-900 rounded-lg min-h-0',
          className
        )}
      >
        <div className="overflow-auto flex-1 flex flex-col justify-center">
          <div className="text-base break-words max-h-full">
            {textArray.map((word, idx) => (
              <React.Fragment key={idx}>
                <span
                  style={{
                    backgroundColor: getBackgroundColor(idx),
                    color: getWordColor(idx),
                  }}
                >
                  {word}
                </span>
                &nbsp;
              </React.Fragment>
            ))}
          </div>
        </div>
        {heatmap && (
          <div className="flex w-full flex-row gap-2 mt-2 justify-center items-center">
            <Tooltip title="Heatmap Range">
              <div>
                <ColorIcon className="w-6 h-6 mt-2" />
              </div>
            </Tooltip>
            <Slider
              max={1}
              min={0}
              step={0.01}
              value={heatmapFilterRange}
              onChange={setHeapMapRange}
            />
          </div>
        )}
      </div>
    );
  }
);
TextHeatMap.displayName = 'TextHeatMap';

export const CLUSTER_COLORS: Array<[number, number, number]> = [
  [250, 211, 144],
  [229, 80, 57],
  [106, 137, 204],
  [184, 233, 148],
  [130, 204, 221],
  [64, 64, 122],
  [52, 172, 224],
  [51, 217, 178],
  [255, 218, 121],
  [255, 177, 66],
  [255, 121, 63],
  [255, 82, 82],
  [179, 57, 57],
];
function getClusterColor(index: number) {
  const color = CLUSTER_COLORS[index % CLUSTER_COLORS.length];
  return `rgb(${color[0]},${color[1]},${color[2]})`;
}

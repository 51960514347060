import { Divider } from '../../ui/mui';
import { Button } from '../../ui/atoms/Button';
import { ExitFullScreenIcon, EnterFullScreenIcon } from '../../ui/icons';
import { useSecretManagers } from '../SecretManagementContext';
import { Select } from '../../ui/atoms/Select';
import { useCallback, useMemo } from 'react';
import { Input } from '../../ui/atoms/Input';
import { DatasetVersionsPopover } from '../../ui/molecules/DatasetVersionsPopover';
import { Title } from '../../ui/atoms/Title';
import { useDatasets } from '../../core/DatasetsContext';
import { ShowFileTreeButton } from './ShowFileTreeButton';
import { testIds } from '../../test-ids';
import { HasChanges } from '../../ui/atoms/HasChanges';

export interface DatasetEditorTopBarProps {
  datasetVersionsPopoverLabel?: string;
}

export function DatasetEditorTopBar({
  datasetVersionsPopoverLabel,
}: DatasetEditorTopBarProps): JSX.Element {
  const { secretManagers } = useSecretManagers();

  const {
    increaseFontSize,
    decreaseFontSize,
    fullScreenMode,
    setFullScreenMode,
    setIsErrorMessageOpen,
    datasetName,
    handleDatasetNameChange,
    virtualFs: { secretManagerId, setSecretManagerId },
    editorDatasetVersion,
    datasetVersions,
    dataset,
    virtualFs: { hasUnsavedCode, setShowFiletree },
    handleSave,
    handleSaveNewDataset,
    resetChanges,
    fetchDatasetVersions,
    handleEditorDatasetVersionSelected,
  } = useDatasets();

  const isNewDataset = !dataset;

  const secretManagersOptions = useMemo(
    () =>
      secretManagers.map((secret) => {
        return { label: secret.name, value: secret.cid };
      }),
    [secretManagers]
  );

  const handleFullScreenClick = useCallback(() => {
    if (fullScreenMode) {
      setFullScreenMode(false);
    } else {
      setShowFiletree(false);
      setIsErrorMessageOpen(false);
      setFullScreenMode(true);
    }
  }, [
    fullScreenMode,
    setFullScreenMode,
    setIsErrorMessageOpen,
    setShowFiletree,
  ]);

  const handleSaveWithParse = useCallback(() => {
    handleSave();
  }, [handleSave]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center content-center justify-between">
        <Title
          className="px-4"
          small
          bottomBorderClassName="border-b-dataset-600"
        >
          SCRIPT EDITOR
        </Title>

        <div className="flex-1 flex flex-row items-center justify-end text-gray-500">
          <Divider orientation="vertical" className="h-5" />
          <Button
            className="!text-2xl w-14 h-14"
            variant="action-icon"
            onClick={increaseFontSize}
          >
            A+
          </Button>
          <Divider orientation="vertical" className="h-5" />
          <Button
            className="!text-xl w-14 h-14"
            variant="action-icon"
            onClick={decreaseFontSize}
          >
            A-
          </Button>

          <>
            <Divider orientation="vertical" className="h-5" />

            <Button
              variant="action-icon"
              onClick={handleFullScreenClick}
              className="w-14 h-14"
              data-testid="full-screen-button"
            >
              {fullScreenMode ? (
                <ExitFullScreenIcon />
              ) : (
                <EnterFullScreenIcon />
              )}
            </Button>
          </>
        </div>
      </div>
      <div className="flex flex-row h-20 items-center content-center justify-between mr-4">
        <div className="flex flex-1 gap-2 m-4 flex-row items-center content-center">
          <ShowFileTreeButton />
          <Input
            label="DATASET NAME"
            value={isNewDataset ? datasetName : dataset.name || ''}
            onChange={handleDatasetNameChange}
            disabled={!isNewDataset}
            className="min-w-[200px]"
          />

          <Select
            label="SECRET MANAGER"
            options={secretManagersOptions}
            onChange={setSecretManagerId}
            value={secretManagerId}
            className="flex-1 min-w-[200px] max-w-[220px]"
          />

          {!isNewDataset && (
            <DatasetVersionsPopover
              datasetVersions={datasetVersions}
              currentDatasetVersion={editorDatasetVersion}
              handleDatasetVersionIdSelected={
                handleEditorDatasetVersionSelected
              }
              fetchDatasetVersions={fetchDatasetVersions}
              hasUnsavedCode={hasUnsavedCode}
              label={datasetVersionsPopoverLabel}
            />
          )}
        </div>

        <div className="flex-1 flex flex-row items-center justify-end">
          <Button variant="text" onClick={resetChanges}>
            {isNewDataset ? 'CANCEL' : 'DISCARD'}
          </Button>
          <HasChanges hasChanges={hasUnsavedCode}>
            <Button
              onClick={
                isNewDataset ? handleSaveNewDataset : handleSaveWithParse
              }
              className="uppercase"
              data-testid={testIds.codeIntegration.parseButton}
            >
              PARSE
            </Button>
          </HasChanges>
        </div>
      </div>
    </div>
  );
}

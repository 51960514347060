import React, { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import { RightPanel } from './RightPanel';
import { ScatterMap } from '../ScatterMap';
import { ScatterMode, useScatterData } from '../ScatterDataContext';
import { ScatterLegend } from './ScatterLegend';
import { NoDataChart } from '../../ui/charts/common/NoDataChart';
import {
  MousePosition,
  SelectionBox,
  useSelectionGroup,
} from '../../core/useSelectionGroup';
import { useResize } from '../../core/useResize';
import { useLocalStorage } from '../../core/useLocalStorage';
import SelectionMenu from './ScatterMenu';
import useKeyComboListener from '../../core/useKeyComboListener';
import { TOUR_SELECTORS_ENUM } from '../../tour/ToursConfig';

interface ScatterAnalyzerItemProps {
  projectId: string;
  sessionRunId: string;
  epoch: number;
  topBar?: React.ReactNode;
  mousePosition: MousePosition;
  className?: string;
}

export const ScatterAnalyzerItem = React.memo<ScatterAnalyzerItemProps>(
  ({ projectId, sessionRunId, epoch, topBar, mousePosition, className }) => {
    const {
      scatterData,
      selection: { someSelected },
      scatterMode,
    } = useScatterData();
    const scatterRef = useRef<HTMLDivElement>(null);

    const hasData = scatterData.samples.length > 0;

    const [initWidth, setInitWidth] = useLocalStorage<number>(
      'SCATTER_RIGHT_PANEL_WIDTH',
      500
    );
    const updateInitWidth = useCallback(
      (width: number) => {
        setInitWidth(width);
      },
      [setInitWidth]
    );
    const { isResizing, onMouseDown, width } = useResize({
      initWidth,
      containerRef: scatterRef,
      minWidth: 50,
      widthOffset: 10,
      onResize: updateInitWidth,
    });

    return (
      <div
        className={clsx(
          'w-full h-full flex flex-col bg-gray-900',
          isResizing && 'cursor-col-resize !select-none',
          className
        )}
        ref={scatterRef}
      >
        {topBar}
        {hasData ? (
          <ScatterViewSessionRunContent
            projectId={projectId}
            sessionRunId={sessionRunId}
            epoch={epoch}
            scatterMode={scatterMode}
            isResizing={isResizing}
            someSelected={someSelected}
            onMouseDown={onMouseDown}
            width={width}
            mousePosition={mousePosition}
          />
        ) : (
          <div className="flex-1 flex relative">
            <NoDataChart text="No Samples" />
          </div>
        )}
      </div>
    );
  }
);

ScatterAnalyzerItem.displayName = 'ScatterAnalyzerItem';

interface ScatterViewSessionRunContentProps {
  projectId: string;
  sessionRunId: string;
  epoch: number;
  scatterMode: ScatterMode;
  isResizing: boolean;
  someSelected: boolean;
  onMouseDown: React.MouseEventHandler<HTMLElement>;
  width?: number;
  mousePosition: MousePosition;
}
function ScatterViewSessionRunContent({
  projectId,
  sessionRunId,
  epoch,
  scatterMode,
  isResizing,
  someSelected,
  onMouseDown,
  width,
  mousePosition,
}: ScatterViewSessionRunContentProps): JSX.Element {
  return (
    <div className="flex w-full h-full flex-1 overflow-hidden">
      <ViewScatterMap
        projectId={projectId}
        sessionRunId={sessionRunId}
        epoch={epoch}
        scatterMode={scatterMode}
        isResizing={isResizing}
      />
      {someSelected && (
        <ViewRightPanel
          onMouseDown={onMouseDown}
          width={width}
          mousePosition={mousePosition}
        />
      )}
    </div>
  );
}

interface ScatterMapProps {
  projectId: string;
  sessionRunId: string;
  epoch: number;
  scatterMode: ScatterMode;
  isResizing: boolean;
}
function ViewScatterMap({
  projectId,
  sessionRunId,
  epoch,
  scatterMode,
  isResizing,
}: ScatterMapProps): JSX.Element {
  const {
    isDrawing,
    startMousePosition,
    endMousePosition,
    handleMouseDown,
    handleMouseMove,
  } = useSelectionGroup();

  const [mouseIn, setMouseIn] = useState(false);
  const isBoxSelectionMode = isDrawing || scatterMode === 'box-selection';
  const pressedKeys = useKeyComboListener(mouseIn);
  const handleMouseEnter = useCallback(() => {
    setMouseIn(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setMouseIn(false);
  }, []);

  return (
    <div
      className={clsx(
        'flex-1 flex relative',
        'select-none',
        isBoxSelectionMode && '!cursor-crosshair',
        isResizing && 'cursor-col-resize pointer-events-none'
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={isBoxSelectionMode ? handleMouseDown : undefined}
      onMouseMove={isDrawing ? handleMouseMove : undefined}
    >
      <ScatterMap
        className="absolute inset-0"
        projectId={projectId}
        sessionRunId={sessionRunId}
        epoch={epoch}
        startMousePosition={startMousePosition}
        endMousePosition={endMousePosition}
        disableGrab={isBoxSelectionMode}
        isDrawing={isDrawing}
      />
      <div className="absolute top-1 bottom-1 right-1 pointer-events-none">
        <ScatterLegend />
      </div>
      {isDrawing && (
        <SelectionBox start={startMousePosition} end={endMousePosition} />
      )}
      <SelectionMenu pressedKeys={pressedKeys} isDrawing={isDrawing} />
    </div>
  );
}

interface ViewRightPanelProps {
  onMouseDown: React.MouseEventHandler<HTMLElement>;
  width?: number;
  mousePosition: MousePosition;
}
function ViewRightPanel({
  onMouseDown,
  width,
  mousePosition,
}: ViewRightPanelProps): JSX.Element {
  return (
    <div
      style={{ width }}
      className="border-l border-gray-800 flex flex-row h-full w-full relative"
      id={TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_RIGHT_PANEL_ID}
    >
      <div
        className="h-full min-w-[4px] z-10 hover:bg-primary-500 cursor-col-resize"
        onMouseDown={onMouseDown}
      />
      <RightPanel mousePosition={mousePosition} />
    </div>
  );
}

import { BaseVisualizationProps, DashletCard } from '../DashletCard';
import { PreviewsContainer } from '../common/PreviewsContainer';
import { PushFiltersIcon } from '../../../ui/icons';
import { NoDataChart } from '../../../ui/charts/common/NoDataChart';
import { ScatterAnalyzerView } from './ScatterAnalyzerView';
import clsx from 'clsx';
import {
  DashletScatterContextProvider,
  useDashletScatterContext,
} from './DashletScatterContext';
import { FilterList } from '../../../filters/FilterList';
import { VisualizationFilter } from '../../../core/types/filters';
import { IconButton } from '@material-ui/core';
import { ProjectionMetricSelector } from '../common/ProjectionMetricSelector';
import { TopBarRightButtons } from '../topbar/TopBarRightButtons';
import { ScatterSettings } from './ScatterSettings';
import { useToggle } from '../../../core/useToggle';
import { useState } from 'react';
import {
  MousePosition,
  getMousePosition,
} from '../../../core/useSelectionGroup';
import { TOUR_SELECTORS_ENUM } from '../../../tour/ToursConfig';

type PopulationExplorationDashletProps = BaseVisualizationProps & {
  defaultFilters?: VisualizationFilter[];
};
const RIGHT_BORDER_CLASSES = 'border-r border-gray-800 border-solid';

export function PopulationExplorationDashlet({
  cid,
  projectId,
  selectedSessionRuns,
  defaultFilters = [],
  pinFilters = [],
  updatePinFilters,
  remove,
  duplicate,
}: PopulationExplorationDashletProps) {
  const [fullScreenMode, toggleFullScreenMode] = useToggle(false);

  const [mousePosition, setMousePosition] = useState<MousePosition>({
    x: 0,
    y: 0,
  });

  return (
    <DashletScatterContextProvider
      projectId={projectId}
      filterProps={{
        defaultFilters,
        pinFilters,
        updatePinFilters,
      }}
    >
      <DashletCard
        fullScreenMode={fullScreenMode}
        toggleFullScreenMode={toggleFullScreenMode}
        className="flex overflow-hidden"
      >
        <div
          onMouseMove={(e) => setMousePosition(getMousePosition(e))}
          className="flex flex-1 bg-gray-850 flex-col overflow-hidden"
          id={TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DASHLET_ID}
        >
          <DashletBar
            remove={remove}
            duplicate={duplicate}
            fullScreenMode={fullScreenMode}
            toggleFullScreenMode={toggleFullScreenMode}
          />
          <div className="flex flex-1">
            {selectedSessionRuns?.length ? (
              <PreviewsContainer flex>
                {selectedSessionRuns.map((sessionRun, i) => (
                  <ScatterAnalyzerView
                    dashletId={cid}
                    key={sessionRun.id}
                    sessionRun={sessionRun}
                    mousePosition={mousePosition}
                    className={clsx(
                      i < selectedSessionRuns.length - 1 && RIGHT_BORDER_CLASSES
                    )}
                  />
                ))}
              </PreviewsContainer>
            ) : (
              <NoResult />
            )}
          </div>
        </div>
      </DashletCard>
    </DashletScatterContextProvider>
  );
}

function NoResult() {
  return (
    <div className="flex flex-col flex-1 bg-gray-900">
      <NoDataChart />
    </div>
  );
}

interface PopulationExplorationDashletTopBarParams {
  remove: () => void;
  duplicate: () => void;
  fullScreenMode: boolean;
  toggleFullScreenMode: () => void;
}

function DashletBar({
  remove,
  duplicate,
  fullScreenMode,
  toggleFullScreenMode,
}: PopulationExplorationDashletTopBarParams) {
  const {
    filters: {
      dashletFilters: localFilters,
      updateDashletFilters: setLocalFilters,
      filterFieldsMeta,
      globalizeFilters,
    },
    projectionMetric,
    setProjectionMetric,
    projectionMetricsFieldsNames,
    settings,
  } = useDashletScatterContext();

  return (
    <div className="flex flex-row h-12 border-b draggable-dashlet allow-dragging text-sm border-solid items-center gap-2 px-3 border-gray-800 overflow-hidden overflow-ellipsis">
      <span className="font-bold uppercase whitespace-nowrap min-w-0 overflow-hidden overflow-ellipsis">
        population exploration
      </span>

      <ProjectionMetricSelector
        projectionMetricsFieldsNames={projectionMetricsFieldsNames}
        projectionMetric={projectionMetric}
        setProjectionMetric={setProjectionMetric}
      />

      <ScatterSettings settings={settings} />

      <FilterList
        expandedByDefault
        filterFieldsMeta={filterFieldsMeta}
        filters={localFilters}
        onFiltersChange={setLocalFilters}
        extraContent={
          <IconButton
            title="Push Filters Back to Dashboard"
            className="w-8 h-8 bg-success-600 hover:bg-success-600"
            onClick={globalizeFilters}
          >
            <PushFiltersIcon className="w-5 h-5 m-0 p-0" />
          </IconButton>
        }
      />

      <span className="flex-1" />

      <TopBarRightButtons
        remove={remove}
        duplicate={duplicate}
        fullScreenMode={fullScreenMode}
        toggleFullScreenMode={toggleFullScreenMode}
      />
    </div>
  );
}

import { PencilIcon, ViIcon, XCloseIcon2 } from '../icons';
import { CircularProgress, Tooltip } from '@material-ui/core';

export type EditIconButtonProps = {
  isOnEditMode: boolean;
  isSaving: boolean;
  editTooltip: JSX.Element | string;
  saveTooltip: JSX.Element | string;
  discardTooltip: JSX.Element | string;
  handleDiscard: () => void;
  handleSave: () => Promise<void>;
  handleEditNoteClick: (e: React.MouseEvent<HTMLElement>) => void;
};

export function EditIconButton({
  isOnEditMode,
  isSaving,
  editTooltip,
  saveTooltip,
  discardTooltip,
  handleDiscard,
  handleSave,
  handleEditNoteClick,
}: EditIconButtonProps) {
  if (isSaving) {
    return (
      <div className="pl-2 pt-2">
        <CircularProgress size="1.5rem" />
      </div>
    );
  }
  return isOnEditMode ? (
    <div className="flex flex-row">
      <Tooltip title={saveTooltip}>
        <button
          onClick={handleSave}
          className="pl-2 group"
          data-testid="save-changes-button"
        >
          <ViIcon className="h-5 w-5 group-hover:h-6 group-hover:w-6 duration-300 bg-success-400 rounded-xl" />
        </button>
      </Tooltip>

      <Tooltip title={discardTooltip}>
        <button
          onClick={handleDiscard}
          className="pl-2 group"
          type="button"
          data-testid="discard-changes-button"
        >
          <XCloseIcon2 className="h-5 w-5 group-hover:h-6 group-hover:w-6 duration-300 bg-error-600 rounded-xl" />
        </button>
      </Tooltip>
    </div>
  ) : (
    <Tooltip title={editTooltip}>
      <button
        onClick={handleEditNoteClick}
        className="pl-2 group"
        type="button"
        data-testid="edit-button"
      >
        <PencilIcon className="h-5 w-5 group-hover:h-6 group-hover:w-6 duration-300" />
      </button>
    </Tooltip>
  );
}

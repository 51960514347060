import { FormEvent, useState } from 'react';
import { parsePositiveInt } from '../../../actions-dialog/helper-functions';
import { Input } from '../../../ui/atoms/Input';
import { useDebounce } from '../../../core/useDebounce';

export type NumberOfSamplesInputProps = {
  initValue: number;
  setValue: (epoch: number) => void;
};

export function NumberOfSamplesInput({
  initValue,
  setValue,
}: NumberOfSamplesInputProps) {
  const [numOfSamples, setNumOfSamples] = useState<number | ''>(initValue);

  const debounceSetValue = useDebounce(setValue, 500);

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const parsedNumber = parsePositiveInt((e.target as HTMLInputElement).value);

    if (typeof parsedNumber === 'number' && parsedNumber > 0) {
      setNumOfSamples(parsedNumber);
      debounceSetValue(parsedNumber);
    } else if (parsedNumber === '') {
      setNumOfSamples('');
    }
  };

  return (
    <Input
      label="Number of samples"
      className="w-36 rounded-md text-center text-sm h-7"
      small
      containerProps={{ className: 'h-7' }}
      type="number"
      value={numOfSamples}
      onChange={handleChange}
    />
  );
}

import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { first } from 'lodash';
import {
  AnalyzeGraphParams,
  Dataset,
  DatasetSetup,
  DatasetVersion,
  GraphAnalyzerData,
  GraphValidatorData,
  JobStatus,
  ModelGraph,
  Node,
  NodeCalculatedData,
  Session,
  Version,
} from '@tensorleap/api-client';

import { Connection } from './interfaces/Connection';
import { useCurrentProject } from '../core/CurrentProjectContext';
import {
  calcConnectionsToUpdate,
  createOldReteData,
  addPositions,
  ChangeNodePropFunc,
  UpdateConnectionFunc,
  getLayersConnectedToLoss,
  groupedModelGraphToModelGraph,
} from './networkStateUtils';
import { PredictionLabel } from './descriptor/labels';
import {
  DatasetMask,
  parseDatasetVersion,
} from '../layer-details/dataset-helper';

import { Position } from '../core/position';
import { COMPONENT_DESCRIPTORS_MAP, NodeType, OutputBlock } from './interfaces';
import { ConnectionDropResult } from './interfaces/drag-and-drop';
import { findByName } from '../core/named-halper';
import {
  CustomLayerData,
  updateCustomLayerNode,
} from '../layer-details/CustomLayerDetails';
import useAsyncEffect from '../core/useAsyncEffect';
import api from '../core/api-client';

import { CustomLossNodeData } from '../layer-details/CustomLossDetails';
import { DUPLICATION_OFFSET } from './consts';
import { usePushNotifications } from '../core/PushNotificationsContext';
import {
  isGraphAnalyzerMessage,
  isValidateAssetsUpdatelMsg,
} from '../core/websocket-message-types';
import { useDebounce } from '../core/useDebounce';
import { reorganizeMap } from './autoorganize';
import {
  isInputsNode,
  isGroundTruthNode,
  isCustomlossNode,
} from './graph-calculation/utils';
import {
  getDatasetOutputData,
  isCustomLayerNode,
  isPredictionNode,
} from './utils';
import { NodePresentationState, Shape } from './graph-calculation/contract';
import {
  ConfirmValidateAssetsDialogMsg,
  ValidateAssetsButtonState,
  ValidateAssetsStatus,
} from './interfaces/ValidateGraphStatus';
import { extractValidateAssetsErrors } from './graph-calculation/GraphValidate';
import { useSaveVersion } from './SaveVersion';
import { connectionToIdentifier } from './helper';
import { SaveVersionStateEnum } from '../core/SaveCommitLoading';
import { calcHash } from '../core/calc-hash';
import { PanZoomControl } from './PanZoom';
import { NetworkTabsEnum } from './NetworkDrawer';
import { useToggle } from '../core/useToggle';
import { OneOfGraphErrorTypes, GraphErrorMsg } from './wizard/errors';
import { GraphErrorKind } from './wizard/types';
import _ from 'lodash';

const ANALYZE_GRAPH_DEBOUNCE_TIME_MS = 500;

type ConnectionChangeProps = {
  changed: Connection[];
  nodes: ROMap<string, Node>;
  connections: Connection[];
};
export type NetworkNodeUiRefs = {
  nodeRef?: HTMLDivElement;
  inputRefs: Map<string, HTMLDivElement>;
  outputRefs: Map<string, HTMLDivElement>;
};

export interface NetworkMapControl {
  nodes: ROMap<Node['id'], Node>;
  connections: Connection[];
  datasetSetup?: DatasetSetup;
  selectedNodeId?: string;
  currentDatasetSetup?: DatasetSetup;
  selectNode: (nodeId: string) => void;
  clearNodeSelection: () => void;
  changeNodeProperty: ChangeNodePropFunc;
  updateNodePosition: (nodeId: string, position: Position) => void;
  addNewConnection: (data: ConnectionDropResult) => void;
  updateConnection: UpdateConnectionFunc;
  addNewNode: (_: {
    name: Node['name'];
    position: Position;
    subType?: string;
  }) => void;
  cloneNode: (nodeId: string) => void;
  spawnNode: (nodeId: string) => void;
  deleteOneOrManyConnections: (
    connection: Connection[],
    isDynamicInput: boolean
  ) => void;
  deleteOneOrManyNodes: (...nodeIds: string[]) => void;
  updateDataset: (dataset: Dataset, datasetVersion?: DatasetVersion) => void;
  saveCurrentVersion: (
    description: string,
    branchName: string,
    copySessions: Session[]
  ) => Promise<void>;
  saveVersion: () => Promise<void>;
  handleToggleModelNodesGrouping: () => void;
  nodeRefs: MutableRefObject<Map<string, NetworkNodeUiRefs>>;
  nodesShapesRef: MutableRefObject<Map<string, NodePresentationState>>;
  hash?: string;
  organizeMap: () => void;
  addPredictionLabel: (_: Node) => void;
  isLoadingShapes: boolean;
  isVersionChanged: boolean;
  getNewNodeId: () => string;
  validateAssetsStatus: ValidateAssetsStatus;
  handleValidateAssetsClicked: (force?: boolean) => Promise<void>;
  validateAssetsButtonState: ValidateAssetsButtonState;
  confirmValidateAssetsDialogIsOpen: boolean;
  setConfirmValidateAssetsDialogIsOpen: Dispatch<SetStateAction<boolean>>;
  overrideSaveDialogIsOpen: boolean;
  setOverrideSaveDialogIsOpen: Dispatch<SetStateAction<boolean>>;
  lockOverrideSaveDialogMsg: string;
  suggestSavingDialog: boolean;
  saveResultMsg?: string;
  saveButtonState: SaveVersionStateEnum;
  saveIconTooltipTitle: string;
  handleSaveClicked: (doOnSave?: () => void) => Promise<void>;
  handleOverrideSaveClicked: () => Promise<void>;
  ungroupedNetworkModelGraph: ModelGraph | undefined;
  onFitNodeToScreen: (nodeId: string) => void;
  setPanZoom: (panZoom: PanZoomControl) => void;
  openNetworkTab?: NetworkTabsEnum;
  setOpenNetworkTab: (_: NetworkTabsEnum | undefined) => void;
  codeIntegrationIsExpanded: boolean;
  toggleCodeIntegrationExpanded: () => void;
  networkContextGeneralError: string | undefined;
  validateAssetsErrors: OneOfGraphErrorTypes[];
  validateAssetsData?: GraphValidatorData;
}

export function useNetworkMap(): NetworkMapControl {
  const [nodes, setNodes] = useState<Map<Node['id'], Node>>(new Map());

  const nodesShapesRef = useRef<Map<Node['id'], NodePresentationState>>(
    new Map()
  );

  const [nodesShapesVersionId, setNodesShapesVersionId] = useState<string>();

  const [isLoadingShapes, setIsLoadingShapes] = useState(true);

  const [codeIntegrationIsExpanded, toggleCodeIntegrationExpanded] = useToggle(
    false
  );

  const [lastGraphShapeDigest, setLastGraphShapeDigest] = useState<string>();
  const [
    lastValidateAssetsDigest,
    setLastValidateAssetsDigest,
  ] = useState<string>();
  const [
    confirmValidateAssetsDialogIsOpen,
    setConfirmValidateAssetsDialogIsOpen,
  ] = useState<boolean>(false);

  const {
    currentVersion,
    maxNodeId,
    saveNewVersion,
    currentModelGraph,
    lastFullModelGraph,
    toggleModelNodesGrouping,
    isModelLayersGrouped,
    fetchValidProjectCid,
    selectedCodeIntegrationVersion,
    setSelectedCodeIntegrationVersion,
    latestVersionId,
    setLatestVersionId,
    loadCodeIntegration,
  } = useCurrentProject();

  const [openNetworkTab, setOpenNetworkTab] = useState<
    NetworkTabsEnum | undefined
  >();

  const projectId = fetchValidProjectCid();

  const [connections, setConnections] = useState<Connection[]>([]);

  const [isVersionChanged, setIsVersionChanged] = useState(false);

  const [modelGraphHash, setModelGraphHash] = useState<string>();

  const [_loadedVersionId, _setLoadedVersionId] = useState<string>();

  const [
    validateAssetsStatus,
    setValidateAssetsStatus,
  ] = useState<ValidateAssetsStatus>(ValidateAssetsStatus.CalculatingDigest);

  const compareSavedAndEditedVersion = useCallback(() => {
    const savedModelGraph = currentVersion?.data;
    if (savedModelGraph) {
      const hasHashChanged = currentVersion?.hash !== modelGraphHash;

      const datasetVersionChanged =
        selectedCodeIntegrationVersion?.cid !==
        currentVersion?.codeIntegrationVersionId;

      if (hasHashChanged || datasetVersionChanged) {
        setIsVersionChanged(true);
        return;
      }

      let editedModelGraph = createOldReteData(nodes, connections);
      if (isModelLayersGrouped && lastFullModelGraph) {
        editedModelGraph = groupedModelGraphToModelGraph(
          editedModelGraph,
          lastFullModelGraph
        );
      }

      setIsVersionChanged(
        !areModelGraphsEqual(editedModelGraph, savedModelGraph)
      );
    }
  }, [
    connections,
    currentVersion?.codeIntegrationVersionId,
    currentVersion?.data,
    currentVersion?.hash,
    isModelLayersGrouped,
    lastFullModelGraph,
    modelGraphHash,
    nodes,
    selectedCodeIntegrationVersion?.cid,
  ]);

  const debounceCompareSavedAndEditedVersion = useDebounce(
    compareSavedAndEditedVersion,
    200
  );

  useEffect(debounceCompareSavedAndEditedVersion, [
    debounceCompareSavedAndEditedVersion,
    nodes,
    connections,
    currentModelGraph,
    currentVersion,
    modelGraphHash,
    selectedCodeIntegrationVersion,
  ]);

  const currentDatasetSetup: DatasetSetup | undefined = useMemo(
    () =>
      selectedCodeIntegrationVersion?.metadata.setup ||
      currentVersion?.datasetSetup,
    [selectedCodeIntegrationVersion, currentVersion]
  );

  const [selectedNodeId, setSelectedNodeId] = useState<string>();

  const selectNode = useCallback((nodeId: string) => {
    setSelectedNodeId(nodeId);
  }, []);

  const { lastServerMessage } = usePushNotifications();

  const [generalError, setGeneralError] = useState<string>();

  const [
    validateAssetsErrorsVersionId,
    setValidateAssetsErrorsVersionId,
  ] = useState<string>();
  const [
    validateAssetsData,
    setValidateAssetsData,
  ] = useState<GraphValidatorData>();
  const [validateAssetsErrors, setValidateAssetsErrors] = useState<
    OneOfGraphErrorTypes[]
  >([]);

  const isDifferentVersion =
    currentVersion?.cid !== validateAssetsErrorsVersionId;

  const validateAssetsButtonState: ValidateAssetsButtonState = useMemo(() => {
    const isDisabled =
      validateAssetsStatus === ValidateAssetsStatus.CalculatingDigest ||
      !selectedCodeIntegrationVersion ||
      !nodes ||
      !connections ||
      !currentVersion ||
      !lastValidateAssetsDigest;

    const confirmDialogMsg =
      validateAssetsStatus === ValidateAssetsStatus.Calculating
        ? ConfirmValidateAssetsDialogMsg.ThereIsAnotherRunningProcess
        : [ValidateAssetsStatus.Passed, ValidateAssetsStatus.Failed].includes(
            validateAssetsStatus
          )
        ? ConfirmValidateAssetsDialogMsg.AlreadyCalculated
        : undefined;

    const tooltipTitle = !selectedCodeIntegrationVersion
      ? 'Select code integration first'
      : validateAssetsStatus === ValidateAssetsStatus.CalculatingDigest ||
        !nodes ||
        !connections ||
        !currentVersion ||
        !lastValidateAssetsDigest
      ? 'Loading your data, please wait a moment...'
      : validateAssetsStatus === ValidateAssetsStatus.Unstarted
      ? 'Click to test your assets'
      : validateAssetsStatus === ValidateAssetsStatus.Calculating
      ? 'Your request is being processed, it may take some time depending on your data'
      : validateAssetsStatus === ValidateAssetsStatus.Passed
      ? 'Your assets are valid!'
      : validateAssetsStatus === ValidateAssetsStatus.Failed
      ? 'Fix the alerts before re-validating your assets'
      : '';

    return { isDisabled, tooltipTitle, confirmDialogMsg };
  }, [
    connections,
    currentVersion,
    lastValidateAssetsDigest,
    nodes,
    selectedCodeIntegrationVersion,
    validateAssetsStatus,
  ]);

  const [storedMaxNodeId, setStoredMaxNodeId] = useState<number>(0);

  const getNewNodeId = useCallback<NetworkMapControl['getNewNodeId']>(() => {
    if (!lastFullModelGraph) {
      return '1';
    }
    const lastFullNodeIds = Array.from(
      Object.values(lastFullModelGraph.nodes)
    ).map((node) => {
      const num = Number(node.id);
      return !isNaN(num) && node.id.trim() !== '' ? num : 0;
    });
    const currentNodesIds = Array.from(nodes.values()).map((node) => {
      const num = Number(node.id);
      return !isNaN(num) && node.id.trim() !== '' ? num : 0;
    });

    const maxNodeId = lastFullNodeIds.length
      ? Math.max(...lastFullNodeIds, ...currentNodesIds, storedMaxNodeId) + 1
      : 1;

    setStoredMaxNodeId(maxNodeId);

    return String(maxNodeId);
  }, [lastFullModelGraph, nodes, storedMaxNodeId]);

  const setAnalyzeGraphState = useCallback(
    (
      digest: string,
      status: JobStatus,
      data: GraphAnalyzerData,
      versionId: string
    ) => {
      setLastGraphShapeDigest(digest);

      const nodesFromMsg = data.nodes;

      const nodesWithErrors = Object.entries(nodesFromMsg).map(
        ([nodeId, { shape, message }]) => {
          const nodePresentationState: NodePresentationState = {
            shape: (shape as Shape) || undefined,
            error: message
              ? {
                  type: GraphErrorKind.node,
                  nodeId,
                  msg: message,
                }
              : undefined,
            receptiveFields: null,
          };

          return [nodeId, nodePresentationState];
        }
      ) as [string, NodeCalculatedData][];

      const newShapes = new Map(nodesWithErrors) as Map<
        string,
        NodePresentationState
      >;

      const currentNodesShapesCopy = new Map(nodesShapesRef.current);

      currentNodesShapesCopy.forEach((_, nodeId) => {
        const newShape = newShapes.get(nodeId);
        if (!newShape) currentNodesShapesCopy.delete(nodeId);
      });
      newShapes.forEach((newShape, nodeId) => {
        const oldShape = currentNodesShapesCopy.get(nodeId);
        if (
          !oldShape ||
          JSON.stringify(oldShape.shape) !== JSON.stringify(newShape.shape) ||
          oldShape.error !== newShape.error
        ) {
          currentNodesShapesCopy.set(nodeId, newShape);
        }
      });

      nodesShapesRef.current = currentNodesShapesCopy;
      setGeneralError(data?.general_error);
      setNodesShapesVersionId(versionId);
      setModelGraphHash(data?.hash);
      setIsLoadingShapes(
        [JobStatus.Pending, JobStatus.Unstarted].includes(status)
      );
    },
    []
  );

  const fetchTriggerAndSetAnalyzeGraphState = useCallback(
    async (
      graphShapeDigest: string,
      modelGraph: ModelGraph,
      currentVersion: Version
    ) => {
      setIsLoadingShapes(true);
      setModelGraphHash(undefined);
      setGeneralError(undefined);
      setLastGraphShapeDigest(graphShapeDigest);
      if (currentVersion.cid !== nodesShapesVersionId) {
        nodesShapesRef.current.clear();
      }

      const analyzeGraphParams: AnalyzeGraphParams = {
        graph: modelGraph,
        datasetVersionId: selectedCodeIntegrationVersion?.cid,
        versionId: currentVersion.cid,
        projectId: currentVersion.projectId,
        digest: graphShapeDigest,
      };
      try {
        const { response, status } = await api.analyzeGraph(analyzeGraphParams);
        if (response)
          setAnalyzeGraphState(
            graphShapeDigest,
            status,
            response,
            currentVersion.cid
          );
      } catch (e) {
        console.error('Something went wrong on analyzeGraph', e);
        setIsLoadingShapes(false);
      }
    },
    [
      nodesShapesVersionId,
      selectedCodeIntegrationVersion?.cid,
      setAnalyzeGraphState,
    ]
  );

  const handleNewValidateAssetsJobState = useCallback(
    (
      validateAssetsDigest: string,
      jobStatus: JobStatus,
      data: GraphValidatorData | undefined
    ) => {
      setLastValidateAssetsDigest(validateAssetsDigest);
      const extractedErrors = extractValidateAssetsErrors(data);

      const newValidateAssetsStatus = calcValidateAssetsStatus(
        jobStatus,
        extractedErrors
      );

      setValidateAssetsData(data);

      const isFinalValidateAssetsResult = [
        ValidateAssetsStatus.Failed,
        ValidateAssetsStatus.Passed,
      ].includes(newValidateAssetsStatus);

      const isDifferentVersion =
        currentVersion?.cid !== validateAssetsErrorsVersionId;

      const shouldAddMissingValidateAssetsAlert =
        (validateAssetsErrors.length === 0 || isDifferentVersion) &&
        [
          ValidateAssetsStatus.Unstarted,
          ValidateAssetsStatus.Calculating,
        ].includes(newValidateAssetsStatus);

      if (isFinalValidateAssetsResult) {
        setValidateAssetsErrors(extractedErrors);
      } else if (shouldAddMissingValidateAssetsAlert) {
        setValidateAssetsErrors([
          {
            title: 'Validate Assets',
            msg: GraphErrorMsg.ValidateAssets,
            type: GraphErrorKind.validateAssets,
            isValidateAssetsError: true,
          },
        ]);
      }

      setValidateAssetsErrorsVersionId(currentVersion?.cid);
      setValidateAssetsStatus(newValidateAssetsStatus);
    },
    [
      currentVersion?.cid,
      validateAssetsErrors.length,
      validateAssetsErrorsVersionId,
    ]
  );

  const ungroupedNetworkModelGraph = useMemo(() => {
    if (
      !currentModelGraph ||
      !currentVersion ||
      _loadedVersionId !== currentVersion.cid
    ) {
      return;
    }
    let originModelGraph = createOldReteData(nodes, connections);

    if (isModelLayersGrouped && lastFullModelGraph) {
      originModelGraph = groupedModelGraphToModelGraph(
        originModelGraph,
        lastFullModelGraph
      );
    }
    return originModelGraph;
  }, [
    _loadedVersionId,
    connections,
    currentModelGraph,
    currentVersion,
    isModelLayersGrouped,
    lastFullModelGraph,
    nodes,
  ]);

  const fetchAndSetValidateAssetsState = useCallback(
    async (validateAssetsDigest: string) => {
      try {
        const { status, data } = await api.getValidateGraphProcessState({
          digest: validateAssetsDigest,
          projectId,
        });

        handleNewValidateAssetsJobState(validateAssetsDigest, status, data);
      } catch (error) {
        console.error('fetchAndSetValidateAssetsState failed', { error });
      }
    },
    [projectId, handleNewValidateAssetsJobState]
  );

  const updateDigestsAndCheckGraph = useCallback(async () => {
    if (!currentVersion) return;

    let modelGraph = createOldReteData(nodes, connections);
    if (isModelLayersGrouped && lastFullModelGraph) {
      modelGraph = groupedModelGraphToModelGraph(
        modelGraph,
        lastFullModelGraph
      );
    }

    const { graphShapeDigest, validateAssetsDigest } = calcDigests(
      modelGraph.nodes,
      selectedCodeIntegrationVersion?.cid
    );

    if (
      ungroupedNetworkModelGraph &&
      graphShapeDigest !== lastGraphShapeDigest
    ) {
      fetchTriggerAndSetAnalyzeGraphState(
        graphShapeDigest,
        ungroupedNetworkModelGraph,
        currentVersion
      );
    }

    if (
      validateAssetsDigest !== undefined &&
      validateAssetsDigest !== lastValidateAssetsDigest
    ) {
      fetchAndSetValidateAssetsState(validateAssetsDigest);
    }
  }, [
    currentVersion,
    nodes,
    connections,
    isModelLayersGrouped,
    lastFullModelGraph,
    selectedCodeIntegrationVersion?.cid,
    ungroupedNetworkModelGraph,
    lastGraphShapeDigest,
    lastValidateAssetsDigest,
    fetchTriggerAndSetAnalyzeGraphState,
    fetchAndSetValidateAssetsState,
  ]);

  const debounceUpdateDigestsAndCheckGraph = useDebounce(() => {
    updateDigestsAndCheckGraph();
  }, ANALYZE_GRAPH_DEBOUNCE_TIME_MS);

  const handleGraphChanged = useCallback(() => {
    if (currentVersion) {
      debounceUpdateDigestsAndCheckGraph();
    }
  }, [currentVersion, debounceUpdateDigestsAndCheckGraph]);

  const clearNodeSelection = useCallback(() => {
    setSelectedNodeId(undefined);
  }, []);

  const changeNodeProperty = useCallback<
    NetworkMapControl['changeNodeProperty']
  >(
    ({
      nodeId,
      nodeDataPropsToUpdate: nodeDataToUpdate,
      nodePropsToUpdate = {},
      override = false,
    }) => {
      setNodes((current) => {
        const node = current.get(nodeId);
        if (!node) return current;

        const updatedNode = {
          ...node,
          ...nodePropsToUpdate,
          data: {
            ...(override ? {} : node.data),
            ...nodeDataToUpdate,
          },
        };

        const newNodes = new Map(current);
        newNodes.set(nodeId, updatedNode);
        return newNodes;
      });
      handleGraphChanged();
    },
    [handleGraphChanged]
  );

  const addPredictionLabel = useCallback(
    (node: Node) => {
      PredictionLabel.add({
        node,
        changeNodeProperty,
        codeIntegrationVersion: selectedCodeIntegrationVersion,
      });
    },
    [changeNodeProperty, selectedCodeIntegrationVersion]
  );

  useEffect(() => {
    if (
      isGraphAnalyzerMessage(lastServerMessage) &&
      lastServerMessage.digest === lastGraphShapeDigest &&
      currentVersion !== undefined
    ) {
      setAnalyzeGraphState(
        lastGraphShapeDigest,
        JobStatus.Finished,
        lastServerMessage.data,
        currentVersion?.cid
      );
    }
  }, [
    currentVersion,
    lastGraphShapeDigest,
    lastServerMessage,
    setAnalyzeGraphState,
  ]);

  useEffect(() => {
    if (
      lastValidateAssetsDigest &&
      isValidateAssetsUpdatelMsg(lastServerMessage)
    ) {
      fetchAndSetValidateAssetsState(lastValidateAssetsDigest);
    }
  }, [
    fetchAndSetValidateAssetsState,
    lastValidateAssetsDigest,
    lastServerMessage,
  ]);

  const onConnectionAdd = useCallback(
    ({ changed, nodes }: ConnectionChangeProps) => {
      getLayersConnectedToLoss(changed, nodes).forEach(addPredictionLabel);
      handleGraphChanged();
    },
    [addPredictionLabel, handleGraphChanged]
  );

  const onConnectionRemove = useCallback(
    ({ changed, nodes }: ConnectionChangeProps) => {
      getLayersConnectedToLoss(changed, nodes).forEach((node) => {
        PredictionLabel.remove({
          node,
          changeNodeProperty,
        });
      });
      handleGraphChanged();
    },
    [changeNodeProperty, handleGraphChanged]
  );

  useAsyncEffect(async () => {
    if (!currentModelGraph?.nodes || !currentVersion) return;

    let codeIntegrationVersion: DatasetVersion | undefined;

    const versionHasChanged = latestVersionId !== currentVersion.cid;

    const selectedCodeIntegrationVersionId = versionHasChanged
      ? currentVersion.codeIntegrationVersionId
      : selectedCodeIntegrationVersion?.cid ||
        currentVersion.codeIntegrationVersionId;

    if (selectedCodeIntegrationVersionId) {
      const { datasetVersion } = await api.getDatasetVersion({
        datasetVersionId: selectedCodeIntegrationVersionId,
      });
      codeIntegrationVersion = datasetVersion;
      setLatestVersionId(currentVersion.cid);
    }

    const nodesForUpdate = Object.values(currentModelGraph.nodes);
    const nodesMapForUpdate = new Map(
      nodesForUpdate.map((node) => [node.id, node])
    );
    const connectionsForUpdates = nodesForUpdate.reduce<Connection[]>(
      (result, outputNode) => {
        const { outputs, id: outputNodeId } = outputNode;
        Object.keys(outputs).forEach((output) => {
          const connections = outputs[output]?.connections;
          connections?.forEach(({ input, node: inputNodeId }) => {
            result.push({
              outputNodeId,
              outputName: output.replace(new RegExp(`^${outputNodeId}-`), ''),
              inputNodeId,
              inputName: input.replace(new RegExp(`^${inputNodeId}-`), ''),
            });
          });
        });

        return result;
      },
      []
    );

    unstable_batchedUpdates(() => {
      if (!selectedCodeIntegrationVersion) {
        loadCodeIntegration();
      }
      setNodes(nodesMapForUpdate);
      setConnections(connectionsForUpdates);
      _setLoadedVersionId(currentVersion.cid);
    });

    getLayersConnectedToLoss(connectionsForUpdates, nodesMapForUpdate).forEach(
      (node) => {
        PredictionLabel.add({
          node,
          changeNodeProperty,
          codeIntegrationVersion,
        });
      }
    );

    handleGraphChanged();
  }, [currentVersion, currentModelGraph, maxNodeId]);

  const addNewConnection = useCallback<NetworkMapControl['addNewConnection']>(
    ({
      inputNodeId,
      inputName,
      outputNodeId,
      outputName,
      isDynamicInput,
      existingConnections,
    }) => {
      setConnections((currentConnections) => {
        const newConnection = {
          inputNodeId,
          inputName,
          outputNodeId,
          outputName,
        };

        const newConnections = existingConnections?.length
          ? currentConnections.filter((c) => !existingConnections.includes(c))
          : [...currentConnections];
        newConnections.push(newConnection);

        if (existingConnections?.length) {
          onConnectionRemove({
            changed: existingConnections,
            nodes,
            connections: newConnections,
          });
        }
        onConnectionAdd({
          changed: [newConnection],
          nodes,
          connections: newConnections,
        });

        return newConnections;
      });

      if (!isDynamicInput) return;

      const nameInData = `${inputNodeId}-${inputName}`;
      const node = nodes.get(inputNodeId);
      const customInputKeys = (node?.data.custom_input_keys || []) as string[];

      if (!customInputKeys.includes(nameInData)) {
        changeNodeProperty({
          nodeId: inputNodeId,
          nodeDataPropsToUpdate: {
            custom_input_keys: [...customInputKeys, nameInData],
          },
        });
      }
    },
    [nodes, changeNodeProperty, onConnectionAdd, onConnectionRemove]
  );

  const updateConnection = useCallback(
    (
      nodeId: string,
      currentInputNames?: string[],
      currentOutputNames?: string[]
    ) => {
      setConnections((connections) => {
        const { previous, added, removed } = calcConnectionsToUpdate({
          connections,
          nodeId,
          currentInputNames,
          currentOutputNames,
        });
        const newConnections = [...previous, ...added];
        if (removed.length) {
          onConnectionRemove({
            changed: removed,
            connections: newConnections,
            nodes,
          });
        }
        if (added.length) {
          onConnectionAdd({
            changed: added,
            connections: newConnections,
            nodes,
          });
        }
        return newConnections;
      });
    },
    [onConnectionRemove, onConnectionAdd, nodes]
  );

  const deleteOneOrManyConnections = useCallback(
    (connections: Connection[], isDynamicInput: boolean) => {
      setConnections((currentConnections) => {
        const connectionsIdentifiersSet = new Set(
          (connections || []).map((c) => connectionToIdentifier(c))
        );

        const newConnections = currentConnections.filter(
          (c) => !connectionsIdentifiersSet.has(connectionToIdentifier(c))
        );

        onConnectionRemove({
          changed: connections,
          connections: newConnections,
          nodes,
        });

        if (!isDynamicInput) {
          return newConnections;
        }
        for (const connection of connections) {
          const node = nodes.get(connection.inputNodeId);
          const customInputKeys = node?.data.custom_input_keys || [];
          const inputNumber = Number(connection.inputName);

          newConnections.forEach((existingConnection, idx) => {
            if (
              existingConnection.inputNodeId === connection.inputNodeId &&
              Number(existingConnection.inputName) > inputNumber
            ) {
              newConnections[idx] = {
                ...existingConnection,
                inputName: (
                  Number(existingConnection.inputName) - 1
                ).toString(),
              };
            }
          });
          changeNodeProperty({
            nodeId: connection.inputNodeId,
            nodeDataPropsToUpdate: {
              custom_input_keys: customInputKeys.slice(),
            },
          });
        }
        return newConnections;
      });
    },
    [nodes, changeNodeProperty, onConnectionRemove]
  );

  const deleteOneOrManyNodes = useCallback(
    (...nodeIds: string[]) => {
      const nodeIdsSet = new Set(nodeIds);
      setConnections((currentConnections) =>
        currentConnections.filter(
          ({ inputNodeId, outputNodeId }) =>
            !nodeIdsSet.has(inputNodeId) && !nodeIdsSet.has(outputNodeId)
        )
      );

      const connectionToDelete = connections.filter(
        ({ inputNodeId, outputNodeId }) =>
          nodeIdsSet.has(inputNodeId) || nodeIdsSet.has(outputNodeId)
      );

      deleteOneOrManyConnections(connectionToDelete, false);

      setNodes((currentNodes) => {
        const newNodes = new Map(currentNodes);
        nodeIds.forEach((nodeId) => newNodes.delete(nodeId));
        return newNodes;
      });
    },
    [connections, deleteOneOrManyConnections]
  );

  const updateNodePosition = useCallback<
    NetworkMapControl['updateNodePosition']
  >(
    (id, position) =>
      setNodes((current) => {
        const node = current.get(id);
        if (!node) {
          return current;
        }
        return new Map(current).set(id, {
          ...node,
          position,
        });
      }),
    []
  );

  const calcDefaultNodeData = useCallback(
    (type: NodeType, subType?: string): Node['data'] => {
      const defaultData = {
        type,
      };

      if (type === 'GroundTruth') {
        const groundTruths = parseDatasetVersion(
          DatasetMask.GroundTruths,
          currentDatasetSetup
        );
        if (groundTruths.length === 1) {
          const output_name = groundTruths[0].fnName;
          return { ...defaultData, output_name, selected: output_name };
        }
      } else if (type === 'Input') {
        const inputs = parseDatasetVersion(
          DatasetMask.Inputs,
          currentDatasetSetup
        );
        if (inputs.length === 1) {
          const output_name = inputs[0].fnName;
          return { ...defaultData, output_name, selected: output_name };
        }
      } else if (type === 'CustomLoss') {
        const customLosses = currentDatasetSetup?.custom_losses;
        if (customLosses?.length === 1) {
          const { name: output_name, arg_names } =
            (!!subType && customLosses.find(({ name }) => name === subType)) ||
            customLosses[0];
          return {
            ...defaultData,
            arg_names,
            output_name,
            selected: output_name,
          };
        }
      } else if (type === 'Visualizer') {
        const visualizations = currentDatasetSetup?.visualizers;
        if (visualizations?.length === 1) {
          const { name: output_name, arg_names } = visualizations[0];
          return {
            ...defaultData,
            arg_names,
            output_name,
            selected: output_name,
          };
        }
      }

      return defaultData;
    },
    [currentDatasetSetup]
  );

  const addNewNode = useCallback<NetworkMapControl['addNewNode']>(
    ({ name, position, subType }) => {
      setNodes((current) => {
        const nodeDescriptor = COMPONENT_DESCRIPTORS_MAP.get(name);
        if (!nodeDescriptor) return current;

        const newNodeId = getNewNodeId();
        const newNode: Node = {
          id: newNodeId,
          name,
          position,
          data: calcDefaultNodeData(nodeDescriptor.type, subType),
          inputs: {},
          outputs: {},
        };
        return new Map(current).set(newNodeId, newNode);
      });
    },
    [calcDefaultNodeData, getNewNodeId]
  );

  const updateDatasetForGroundTruthNode = useCallback(
    (node: Node, datasetVersion?: DatasetVersion) => {
      const { output_name } = node.data;

      const outputs = datasetVersion?.metadata.setup?.outputs || [];

      let selectedOutput = findByName(outputs, output_name);

      if (!selectedOutput && outputs.length === 1) {
        [selectedOutput] = outputs;
      }

      updateConnection(
        node.id,
        undefined,
        outputs.map((o) => o.name)
      );

      changeNodeProperty({
        nodeId: node.id,
        nodeDataPropsToUpdate: { output_name: selectedOutput?.name },
      });
    },
    [changeNodeProperty, updateConnection]
  );

  const updateDatasetForPredictionNode = useCallback(
    (node: Node, datasetVersion?: DatasetVersion) => {
      const { prediction_type } = node.data;
      const predictions =
        datasetVersion?.metadata.setup?.prediction_types || [];

      let newPrediction = predictions.find(
        ({ name }) => prediction_type === name
      );

      if (!newPrediction && predictions.length === 1) {
        [newPrediction] = predictions;
      }

      changeNodeProperty({
        nodeId: node.id,
        nodeDataPropsToUpdate: { prediction_type: newPrediction?.name },
      });
    },
    [changeNodeProperty]
  );

  const updateDatasetForCustomLayerNode = useCallback(
    (node: Node, datasetVersion?: DatasetVersion) => {
      const {
        selected,
        type: _type,
        ...init_props
      } = node.data as CustomLayerData;
      if (!selected) return;

      const currentCustomLayers =
        datasetVersion?.metadata.modelSetup?.custom_layers;

      const newCustomLayer =
        currentCustomLayers?.length === 1
          ? first(currentCustomLayers)
          : findByName(currentCustomLayers, selected);

      const previousPropsValues = new Map(Object.entries(init_props));

      updateCustomLayerNode(
        node,
        changeNodeProperty,
        newCustomLayer,
        previousPropsValues
      );
    },
    [changeNodeProperty]
  );

  const updateDatasetForCustomLossNode = useCallback(
    (node: Node, datasetVersion?: DatasetVersion) => {
      const { selected, type } = node.data as CustomLossNodeData;

      const currentCustomLosses = datasetVersion?.metadata.setup?.custom_losses;

      const newCustomLayer =
        currentCustomLosses?.length === 1
          ? first(currentCustomLosses)
          : findByName(currentCustomLosses, selected);

      const { arg_names = [], name } = newCustomLayer || {};
      const user_unique_name = node.data.user_unique_name || '';

      changeNodeProperty({
        nodeId: node.id,
        nodeDataPropsToUpdate: {
          arg_names,
          user_unique_name,
          name,
          selected: name,
          type,
        },
        override: true,
      });

      updateConnection(node.id, arg_names);
    },
    [changeNodeProperty, updateConnection]
  );

  const updateDatasetForDatasetNode = useCallback(
    (node: Node, datasetVersion?: DatasetVersion) => {
      const newOutputNames = getDatasetOutputData(
        datasetVersion?.metadata.setup
      ).map(({ name }) => name);

      updateConnection(node.id, undefined, newOutputNames);
    },
    [updateConnection]
  );

  const updateDataset = useCallback(
    async (dataset: Dataset, datasetVersion?: DatasetVersion) => {
      const newDatasetVersion: DatasetVersion | undefined =
        datasetVersion || dataset.latestValidVersion || undefined;
      for (const node of Array.from(nodes.values())) {
        if (isInputsNode(node)) {
          updateDatasetForDatasetNode(node, newDatasetVersion);
        } else if (isGroundTruthNode(node)) {
          updateDatasetForGroundTruthNode(node, newDatasetVersion);
        } else if (isPredictionNode(node)) {
          updateDatasetForPredictionNode(node, newDatasetVersion);
        } else if (isCustomLayerNode(node)) {
          updateDatasetForCustomLayerNode(node, newDatasetVersion);
        } else if (isCustomlossNode(node)) {
          updateDatasetForCustomLossNode(node, newDatasetVersion);
        }
      }
      setSelectedCodeIntegrationVersion(newDatasetVersion);
      handleGraphChanged();
    },
    [
      setSelectedCodeIntegrationVersion,
      handleGraphChanged,
      nodes,
      updateDatasetForDatasetNode,
      updateDatasetForGroundTruthNode,
      updateDatasetForPredictionNode,
      updateDatasetForCustomLayerNode,
      updateDatasetForCustomLossNode,
    ]
  );

  const cloneNode = useCallback<NetworkMapControl['cloneNode']>(
    (nodeId) =>
      setNodes((current) => {
        const nodeToClone = current.get(nodeId);
        if (!nodeToClone) {
          return current;
        }

        const newNodeId = getNewNodeId();
        const { origin_name: _, ...clonedData } = structuredClone(
          nodeToClone.data
        );

        const clonedNode: Node = {
          ...nodeToClone,
          data: clonedData,
          id: newNodeId,
          position: addPositions(
            nodeToClone.position as Position,
            DUPLICATION_OFFSET
          ),
          inputs: {},
          outputs: {},
        };

        return new Map(current.entries()).set(newNodeId, clonedNode);
      }),
    [getNewNodeId]
  );

  const spawnNode = useCallback<NetworkMapControl['cloneNode']>(
    (nodeId) => {
      setNodes((currentMap) => {
        const nodeToClone = currentMap.get(nodeId);
        if (!nodeToClone) {
          return currentMap;
        }

        const newNodeId = getNewNodeId();

        const outputBlocks =
          (nodeToClone.data.output_blocks as OutputBlock[]) || [];
        outputBlocks.push({ block_node_id: newNodeId });
        const updatedNodeToClone = {
          ...nodeToClone,
          data: { ...nodeToClone.data, output_blocks: outputBlocks },
        };

        const clonedNode: Node = {
          data: {
            parent_name: nodeToClone.name,
            nodecid: nodeToClone.id,
          },
          id: newNodeId,
          name: 'Representation Block',
          position: addPositions(
            nodeToClone.position as Position,
            DUPLICATION_OFFSET
          ),
          inputs: {},
          outputs: {},
        };

        return new Map(currentMap.entries())
          .set(newNodeId, clonedNode)
          .set(nodeId, updatedNodeToClone);
      });
    },
    [getNewNodeId]
  );

  const saveCurrentVersion = useCallback(
    async (
      description: string,
      branchName: string,
      copySessions: Session[]
    ) => {
      if (!currentDatasetSetup) return;
      await saveNewVersion({
        modelGraph: createOldReteData(nodes, connections),
        description,
        branchName,
        datasetSetup: currentDatasetSetup,
        codeIntegrationVersionId: selectedCodeIntegrationVersion?.cid,
        hash: modelGraphHash,
        copySessions: copySessions,
      });
    },
    [
      nodes,
      connections,
      saveNewVersion,
      currentDatasetSetup,
      selectedCodeIntegrationVersion,
      modelGraphHash,
    ]
  );

  const saveVersion = useCallback(async () => {
    if (!currentVersion) {
      console.error('Somehow tried to save without currentVersion');
      return;
    }
    let modelGraph = createOldReteData(nodes, connections);
    if (isModelLayersGrouped && lastFullModelGraph) {
      modelGraph = groupedModelGraphToModelGraph(
        modelGraph,
        lastFullModelGraph
      );
    }

    await api.updateVersion({
      projectId,
      versionId: currentVersion.cid,
      data: modelGraph,
      codeIntegrationVersionId: selectedCodeIntegrationVersion?.cid,
      datasetSetup: currentDatasetSetup,
      hash: modelGraphHash,
    });
  }, [
    connections,
    currentDatasetSetup,
    currentVersion,
    modelGraphHash,
    isModelLayersGrouped,
    lastFullModelGraph,
    nodes,
    projectId,
    selectedCodeIntegrationVersion?.cid,
  ]);

  const organizeMap = useCallback(() => {
    setNodes((currentNodes) => {
      const { nodes } = createOldReteData(currentNodes, connections);
      const postionedNodes = reorganizeMap(nodes);
      return new Map(
        Object.values(postionedNodes).map((node) => [node.id, node])
      );
    });
  }, [connections]);

  const handleToggleModelNodesGrouping = useCallback(() => {
    const presentedModelGraph = createOldReteData(nodes, connections);
    toggleModelNodesGrouping(presentedModelGraph, !isModelLayersGrouped);
  }, [nodes, connections, toggleModelNodesGrouping, isModelLayersGrouped]);

  const validateAssets = useCallback(async () => {
    setConfirmValidateAssetsDialogIsOpen(false);
    if (
      !selectedCodeIntegrationVersion ||
      !nodes ||
      !connections ||
      !currentVersion ||
      !lastValidateAssetsDigest
    ) {
      console.error(
        'Cant validate graph assets since some of the params are missing',
        {
          selectedCodeIntegrationVersion,
          currentVersion,
          nodes,
          connections,
          digest: lastValidateAssetsDigest,
        }
      );
      return;
    }

    let modelGraph = createOldReteData(nodes, connections);
    if (isModelLayersGrouped && lastFullModelGraph) {
      modelGraph = groupedModelGraphToModelGraph(
        modelGraph,
        lastFullModelGraph
      );
    }

    const validateGraphParams = {
      graph: modelGraph,
      datasetVersionId: selectedCodeIntegrationVersion.cid,
      versionId: currentVersion.cid,
      projectId: currentVersion.projectId,
      digest: lastValidateAssetsDigest,
    };
    await api.validateGraph(validateGraphParams);
  }, [
    connections,
    currentVersion,
    isModelLayersGrouped,
    lastFullModelGraph,
    lastValidateAssetsDigest,
    nodes,
    selectedCodeIntegrationVersion,
  ]);

  const handleValidateAssetsClicked = useCallback(
    async (force?: boolean) => {
      {
        if (validateAssetsButtonState.confirmDialogMsg === undefined || force) {
          setConfirmValidateAssetsDialogIsOpen(false);
          validateAssets();
        } else {
          setConfirmValidateAssetsDialogIsOpen(true);
        }
      }
    },
    [validateAssets, validateAssetsButtonState.confirmDialogMsg]
  );

  const {
    overrideSaveDialogIsOpen,
    setOverrideSaveDialogIsOpen,
    lockOverrideSaveDialogMsg,
    suggestSavingDialog,
    saveResultMsg,
    saveButtonState,
    saveIconTooltipTitle,
    handleSaveClicked,
    handleOverrideSaveClicked,
  } = useSaveVersion({
    saveVersion,
    isVersionChanged,
    modelGraphHash,
    isLoadingShapes,
  });

  const nodeRefs = useRef(new Map<string, NetworkNodeUiRefs>());
  const [panZoom, setPanZoom] = useState<PanZoomControl>();

  const onFitNodeToScreen = useMemo(
    () =>
      panZoom?.onFitNodeToScreen ||
      (() => {
        console.warn('onFitNodeToScreen is not defined');
      }),
    [panZoom?.onFitNodeToScreen]
  );

  const value = useMemo<NetworkMapControl>(
    () => ({
      nodes,
      datasetSetup: currentDatasetSetup,
      connections,
      selectedNodeId,
      selectNode,
      currentDatasetSetup,
      clearNodeSelection,
      changeNodeProperty,
      updateNodePosition,
      addNewConnection,
      updateConnection,
      handleToggleModelNodesGrouping,
      addNewNode,
      cloneNode,
      spawnNode,
      deleteOneOrManyConnections,
      deleteOneOrManyNodes,
      updateDataset,
      saveCurrentVersion,
      saveVersion,
      nodeRefs,
      nodesShapesRef,
      hash: modelGraphHash,
      organizeMap,
      addPredictionLabel,
      isLoadingShapes,
      isVersionChanged,
      getNewNodeId,
      validateAssetsStatus,
      handleValidateAssetsClicked,
      validateAssetsButtonState,
      confirmValidateAssetsDialogIsOpen,
      setConfirmValidateAssetsDialogIsOpen,
      overrideSaveDialogIsOpen,
      setOverrideSaveDialogIsOpen,
      lockOverrideSaveDialogMsg,
      suggestSavingDialog,
      saveResultMsg,
      saveButtonState,
      saveIconTooltipTitle,
      handleSaveClicked,
      handleOverrideSaveClicked,
      ungroupedNetworkModelGraph,
      onFitNodeToScreen,
      setPanZoom,
      openNetworkTab,
      setOpenNetworkTab,
      codeIntegrationIsExpanded,
      toggleCodeIntegrationExpanded,
      networkContextGeneralError: generalError,
      validateAssetsErrors: isDifferentVersion ? [] : validateAssetsErrors,
      validateAssetsData,
    }),
    [
      nodes,
      currentDatasetSetup,
      connections,
      selectedNodeId,
      selectNode,
      clearNodeSelection,
      changeNodeProperty,
      updateNodePosition,
      addNewConnection,
      updateConnection,
      handleToggleModelNodesGrouping,
      addNewNode,
      cloneNode,
      spawnNode,
      deleteOneOrManyConnections,
      deleteOneOrManyNodes,
      updateDataset,
      saveCurrentVersion,
      saveVersion,
      modelGraphHash,
      organizeMap,
      addPredictionLabel,
      isLoadingShapes,
      isVersionChanged,
      getNewNodeId,
      validateAssetsStatus,
      handleValidateAssetsClicked,
      validateAssetsButtonState,
      confirmValidateAssetsDialogIsOpen,
      overrideSaveDialogIsOpen,
      setOverrideSaveDialogIsOpen,
      lockOverrideSaveDialogMsg,
      suggestSavingDialog,
      saveResultMsg,
      saveButtonState,
      saveIconTooltipTitle,
      handleSaveClicked,
      handleOverrideSaveClicked,
      ungroupedNetworkModelGraph,
      onFitNodeToScreen,
      openNetworkTab,
      codeIntegrationIsExpanded,
      toggleCodeIntegrationExpanded,
      generalError,
      isDifferentVersion,
      validateAssetsErrors,
      validateAssetsData,
    ]
  );
  return value;
}
function calcDigest(
  nodes: Record<string, Node>,
  isAffectHashNode: (node: Node) => boolean,
  datasetVersionId?: string
) {
  const clonedNodes: Record<string, Node> = structuredClone(nodes);
  const affectedHashNodes = Object.values(clonedNodes).filter(isAffectHashNode);

  const affectedHashNodesIds = new Set(affectedHashNodes.map(({ id }) => id));

  const modifiedNodes = affectedHashNodes.map((node) =>
    keepOnlyHashNodeAffectedFields(node, affectedHashNodesIds)
  );

  return calcHash({ modifiedNodes, datasetVersionId });
}

function calcDigests(
  nodes: Record<string, Node>,
  datasetVersionId?: string
): { graphShapeDigest: string; validateAssetsDigest: string | undefined } {
  const graphShapeDigest = calcDigest(nodes, isAffectGraphShapesHashNode);
  const validateAssetsDigest =
    datasetVersionId === undefined
      ? undefined
      : calcDigest(nodes, isAffectValidateAssetsHashNode, datasetVersionId);
  return { graphShapeDigest, validateAssetsDigest };
}

const AFFECTED_GRAPH_SHAPE_HASH_NODES_TYPES = new Set([
  'Input',
  'Layer',
  'CustomLayer',
]);
function isAffectGraphShapesHashNode(node: Node): boolean {
  return (
    AFFECTED_GRAPH_SHAPE_HASH_NODES_TYPES.has(node.data['type']) ||
    node.name === 'Representation Block'
  );
}

const AFFECTED_VALIDATE_ASSETS_HASH_NODES_TYPES = new Set([
  'Input',
  'Layer',
  'CustomLayer',
  'GroundTruth',
  'Dataset',
  'DatasetOutput',
  'Metric',
  'Visualizer',
  'CustomLoss',
  'Loss',
  'Optimizer',
]);
function isAffectValidateAssetsHashNode(node: Node): boolean {
  return (
    AFFECTED_VALIDATE_ASSETS_HASH_NODES_TYPES.has(node.data['type']) ||
    node.name === 'Representation Block'
  );
}

function keepOnlyHashNodeAffectedFields(
  node: Node,
  affectedHashNodesIds: Set<string>
) {
  const inputsNodesIds = Object.values(node.inputs)
    .map(({ connections }) => (connections || []).map(({ node }) => node))
    .flat();

  const inputs = inputsNodesIds.filter((inputNodeId) =>
    affectedHashNodesIds.has(inputNodeId)
  );

  const { prediction_type: _, ...data } = node.data; //prediction_type doesn't affected the hash and it could be changed when the output connection is changing, so we need to filter it out

  return { data, id: node.id, inputs };
}

function calcValidateAssetsStatus(
  status: JobStatus,
  errors: unknown[]
): ValidateAssetsStatus {
  if (JobStatus.Unstarted === status) {
    return ValidateAssetsStatus.Unstarted;
  }

  if ([JobStatus.Pending, JobStatus.Started].includes(status)) {
    return ValidateAssetsStatus.Calculating;
  }

  if (
    errors.length > 0 ||
    [JobStatus.Failed, JobStatus.Stopped, JobStatus.Terminated].includes(status)
  ) {
    return ValidateAssetsStatus.Failed;
  }

  if (JobStatus.Finished === status) {
    return ValidateAssetsStatus.Passed;
  }

  console.error('Cant calculate validate-graph-status for unnknown jobStatus', {
    status,
  });
  return ValidateAssetsStatus.Unstarted;
}
const areModelGraphsEqual = (modelA: ModelGraph, modelB: ModelGraph): boolean =>
  isModelContainsModel(modelA, modelB) && isModelContainsModel(modelB, modelA);

const isModelContainsModel = (
  modelA: ModelGraph,
  modelB: ModelGraph
): boolean =>
  Object.entries(modelB.nodes).every(([nodeId, nodeB]) => {
    const nodeA = modelA.nodes[nodeId];
    return nodeA && areNodesEqual(nodeA, nodeB);
  });

const areNodesEqual = (nodeA: Node, nodeB: Node) =>
  nodeA.id === nodeB.id &&
  nodeA.name === nodeB.name &&
  compareDictionariesWhileIgnoreUndefinedValues(nodeA.data, nodeB.data) &&
  _.isEqual(nodeA.inputs, nodeB.inputs) &&
  _.isEqual(nodeA.outputs, nodeB.outputs);

function compareDictionariesWhileIgnoreUndefinedValues(
  dictA: Record<string, unknown>,
  dictB: Record<string, unknown>
): boolean {
  const filteredDictA = Object.entries(dictA).filter(
    ([_, value]) => value !== undefined
  );
  const filteredDictB = Object.entries(dictB).filter(
    ([_, value]) => value !== undefined
  );

  return _.isEqual(filteredDictA, filteredDictB);
}
